import React, { useState, useEffect } from 'react'
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  RadioGroup,
  Radio,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from 'utils/types'

export interface RadioButtonItem {
  value: any
  label: string
}
interface Props {
  items: RadioButtonItem[]
  value?: any
  name?: string
  withinForm?: boolean
  label?: string
  error?: string
  onChange?(e: any): void
  direction?: 'horizontal' | 'vertical'
}

const useStyles = makeStyles<Theme>(theme => ({
  colorSecondary: {
    color: `${theme.palette.textGrey} !important`,
  },
}))

const RadioButtons = React.forwardRef<typeof RadioGroup, Props>(
  (
    {
      items,
      onChange,
      value,
      direction = 'horizontal',
      label,
      name = 'radiogroup',
      withinForm,
      error,
    },
    ref
  ) => {
    const [val, setVal] = useState(value)
    const classes = useStyles()

    useEffect(() => {
      setVal(value)
    }, [value])

    const handleChange = event => {
      const {
        target: { value: newValue },
      } = event
      setVal(newValue)
      if (withinForm) {
        onChange?.(event)
      } else {
        onChange?.(newValue)
      }
    }
    return (
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          innerRef={ref}
          aria-label={label}
          name={name}
          value={val}
          onChange={handleChange}
          row={direction === 'horizontal'}
        >
          {items?.map(item => (
            <FormControlLabel
              value={item.value}
              control={<Radio classes={classes} />}
              label={item.label}
              key={item.value}
            />
          ))}
          {error && <FormHelperText>{error}</FormHelperText>}
        </RadioGroup>
      </FormControl>
    )
  }
)

export default RadioButtons
