import React from 'react'
import { FinalNoticeRadioButton } from 'components/atoms'
import { HeaderFinalNotice } from 'utils/types'

interface Props {
  value?: string
  onChange(id: string): void
  items?: HeaderFinalNotice[]
}

const FinalNoticeRadioButtonGroup = React.forwardRef<HTMLDivElement, Props>(
  ({ onChange, items, value }, ref) => (
    <div ref={ref}>
      {items?.map(item => (
        <FinalNoticeRadioButton value={value} key={item.id} onChange={onChange} notice={item} />
      ))}
    </div>
  )
)

export default FinalNoticeRadioButtonGroup
