import React, { LazyExoticComponent, Suspense } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Loading } from '../components/atoms'
import { isLogin, isActive } from '../utils/helper'
import TimeOut from '../utils/TimeOut'

interface Props {
  component: LazyExoticComponent<any>
  [x: string]: any
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => {
      // eslint-disable-next-line react/prop-types
      if (!isActive() && isLogin() && props.location.pathname !== '/inactive')
        return <Redirect to="/inactive" />

      if (isLogin())
        return (
          <>
            <Suspense fallback={<Loading />}>
              <TimeOut />
            </Suspense>
            <Suspense fallback={<Loading />}>
              <Component {...props} />
            </Suspense>
          </>
        )
      return <Redirect to="/login" />
    }}
  />
)

export default PrivateRoute
