import { gql } from '@apollo/client'

export const CREATE_UPLOAD_MUTATION = gql`
  mutation createUpload($attributes: UploadInput!) {
    createUpload(attributes: $attributes) {
      attachmentUrl
      category
      id
    }
  }
`

export const DELETE_UPLOAD_MUTATION = gql`
  mutation deleteUpload($id: ID!) {
    deleteUpload(id: $id)
  }
`

export const CREATE_POSITION_MUTATION = gql`
  mutation createPostitions(
    $isAdvanced: Boolean!
    $noticeId: ID!
    $positions: [PositionInput!]!
    $notes: String
    $draft: Boolean
    $orderNos: [OrderNoInput!]
  ) {
    createPositions(
      isAdvanced: $isAdvanced
      noticeId: $noticeId
      positions: $positions
      notes: $notes
      draft: $draft
      orderNos: $orderNos
    )
  }
`

export const UPLOAD_ADDITIONAL_AGREEMENT = gql`
  mutation uploadAdditionalAgreement($customerId: ID!, $blobId: String!) {
    uploadAdditionalAgreement(customerId: $customerId, blobId: $blobId) {
      agreementNo
      cityCustomer
      companyCustomer
      createdAt
      emailInvoice
      emailUser
      id
      streetCustomer
      updatedAt
      zipCustomer
      uploadedAdditionalAgreement
    }
  }
`

export const REGISTER_MUTATION = gql`
  mutation register($attributes: UserInput!) {
    register(attributes: $attributes)
  }
`

export const CREATE_SUPPORT_REQUEST_MUTATION = gql`
  mutation createSupportRequest($attributes: SupportRequestInput!) {
    createSupportRequest(attributes: $attributes) {
      id
    }
  }
`

export const UPDATE_CUSTOMER_MUTATION = gql`
  mutation updateCustomer($id: ID!, $attributes: CustomerInput!) {
    updateCustomer(id: $id, attributes: $attributes) {
      id
    }
  }
`

export const UPDATE_USER_MUTATION = gql`
  mutation updateUser($id: ID!, $attributes: UserUpdate!) {
    updateUser(id: $id, attributes: $attributes) {
      id
      firstName
      lastName
      phone
      locale
    }
  }
`
