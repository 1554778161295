import { css, createGlobalStyle, ThemeProps } from 'styled-components'
import { Theme } from './types'

const GlobalStyle = createGlobalStyle<ThemeProps<Theme>>(
  props => css`
    body {
      margin: 0;
      font-weight: 300;
      color: ${props.theme.colorBlack};
      background-color: #efefef;
    }
    #root {
      background-color: white;
      max-width: 120rem;
      width: 100%;
      margin: 0 auto;
    }
    p {
      margin: 0;
    }
  `
)

export default GlobalStyle
