import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Snackbar as MuiSnackbar, SnackbarProps as MSnackbarProps } from '@material-ui/core'
import Slide, { SlideProps } from '@material-ui/core/Slide'
import { withStyles } from '@material-ui/core/styles'
import { Alert as MAlert, AlertTitle as MAlertTitle } from '@material-ui/lab'

type Horizontal = 'left' | 'center' | 'right'
type Vertical = 'top' | 'bottom'
export type Severity = 'error' | 'warning' | 'info' | 'success'
type TransitionProps = Omit<SlideProps, 'direction'>

export interface SnackbarProps extends MSnackbarProps {
  severity?: Severity
  onClose?(): void
  small?: boolean
}

const TransitionUp = (props: TransitionProps) => <Slide {...props} direction="up" />

const Snackbar: React.FC<SnackbarProps> = ({
  message,
  severity,
  onClose,
  anchorOrigin,
  title,
  small,
  ...props
}) => {
  const [transition, setTransition] = useState<React.ComponentType<TransitionProps> | undefined>(
    undefined
  )
  const [open, setOpen] = useState<boolean>(false)
  const { t } = useTranslation('common')

  const handleClick = useCallback(
    (Transition: React.ComponentType<TransitionProps>) => {
      setTransition(() => Transition)
      setOpen(true)
    },
    [setTransition, setOpen]
  )

  useEffect(() => {
    if (props.open) {
      handleClick(TransitionUp)
    }
  }, [props.open, handleClick])

  const handleClose = () => {
    setOpen(false)
    onClose?.()
  }

  const alertTitle = () => {
    if (title) return title
    if (severity === 'error') return t('common:error')
    if (severity === 'warning') return t('common:warning')
    if (severity === 'info') return t('common:info')
    if (severity === 'success') return t('common:success')
    return null
  }

  const commonProps = {
    TransitionComponent: transition,
    open,
    onClose: handleClose,
    key: message as string,
    anchorOrigin: anchorOrigin || {
      horizontal: 'right' as Horizontal,
      vertical: 'top' as Vertical,
    },
  }

  const Alert = withStyles({
    root: {
      paddingLeft: 30,
      paddingRight: 50,
      whiteSpace: 'pre-line',
      width: '100%',
      alignItems: 'center',
      ...(!small && {
        '& .MuiAlert-icon': {
          fontSize: '2.5rem',
        },
      }),
      fontSize: '0.875rem',
    },
  })(MAlert)

  const AlertTitle = withStyles(() => ({
    root: {
      padding: 0,
      margin: 0,
      fontSize: '1.125rem',
    },
  }))(MAlertTitle)
  return (
    <MuiSnackbar {...props} {...commonProps}>
      <Alert onClose={handleClose} severity={severity}>
        {alertTitle() ? (
          <AlertTitle style={{ padding: 0, margin: 0 }}>{alertTitle()}</AlertTitle>
        ) : null}
        {message}
      </Alert>
    </MuiSnackbar>
  )
}

export default Snackbar
