import React from 'react'
import { Avatar, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Mail, Phone } from 'assets/icons'
import { Text } from 'components/atoms'
import { Theme } from 'utils/types'
import Col from './Col'
import Container from './Container'
import Row from './Row'

interface Props {
  imgAlt?: string
  imgSrc: string
  name: string
  jobTitle: string
  phone: string
  email: string
  imgOptions?: object
}

const useStyles = makeStyles<Theme>(theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.textGrey,
    '&:visited': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  icon: {
    marginRight: '4px',
  },
  jobTitle: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    color: theme.palette.textGrey,
  },
}))

const ContactInfo: React.FC<Props> = ({
  imgSrc,
  imgAlt = 'image',
  imgOptions,
  name,
  jobTitle,
  phone,
  email,
}) => {
  const classes = useStyles()
  const textStyle = {
    $color: 'inherit',
    fontSize: '0.875rem',
    lineHeight: '1.5',
    fontWeight: '600',
  }
  return (
    <Container>
      <Col padding="15px 15px 0 0">
        <Avatar src={imgSrc} alt={imgAlt} {...imgOptions} />
      </Col>
      <Col>
        <Typography variant="h2">{name}</Typography>
        <Text className={classes.jobTitle} {...textStyle} $italic fontWeight="400">
          {jobTitle}
        </Text>
        <a className={classes.link} href={`tel:${phone}`}>
          <Row>
            <Phone className={classes.icon} />
            <Text {...textStyle}>{phone}</Text>
          </Row>
        </a>
        <a className={classes.link} href={`mailto:${email}`}>
          <Row>
            <Mail className={classes.icon} />
            <Text {...textStyle}>{email}</Text>
          </Row>
        </a>
      </Col>
    </Container>
  )
}

export default ContactInfo
