import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { RadioSelected, RadioUnselected } from 'assets/icons'
import { HeaderFinalNotice, Theme } from 'utils/types'

interface Props {
  value?: string
  notice?: HeaderFinalNotice
  onChange?(id: string): void
  isTitle?: boolean
}

const useStyles = makeStyles<Theme>(theme => ({
  red: {
    color: theme.palette.red,
  },
  orange: {
    color: theme.palette.error.main,
  },
  green: {
    color: theme.palette.primary.main,
  },
  blue: {
    color: theme.palette.secondary.main,
  },
  row: {
    cursor: 'pointer',
    marginBottom: theme.spacing(1),
  },
}))

const FinalNoticeRadioButton: React.FC<Props> = ({ value, onChange, notice, isTitle }) => {
  const { t } = useTranslation(['common', 'viewReport'])
  const classes = useStyles()
  const getClassName = (val: string) => {
    let returnValue = classes.grey
    switch (val) {
      case 'offen':
        returnValue = classes.red
        break
      case 'Testat angefordert':
        returnValue = classes.red
        break
      case 'zwischengespeichert':
        returnValue = classes.orange
        break
      case 'gesendet':
        returnValue = classes.green
        break
      case 'geprüft':
        returnValue = classes.green
        break
      case 'fakturiert':
        returnValue = classes.blue
        break
      default:
        returnValue = classes.grey
        break
    }
    return returnValue
  }

  if (isTitle)
    return (
      <Grid container spacing={1}>
        <Grid item md={1} />
        <Grid item md={1}>
          <Typography variant="caption">{t('country')}</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography variant="caption">{t('from')}</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography variant="caption">{t('till')}</Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="caption">{t('finalNotice:step1SubmitNotice')}</Typography>
        </Grid>
        <Grid item md={3}>
          <Typography variant="caption">{t('finalNotice:step2SubmitCertificate')}</Typography>
        </Grid>
      </Grid>
    )
  if (!!notice)
    return (
      <Grid className={classes.row} container spacing={1} onClick={() => onChange?.(notice.id)}>
        <Grid item md={1}>
          {value === notice.id ? <RadioSelected /> : <RadioUnselected />}
        </Grid>
        <Grid item md={1}>
          <Typography variant="body1">{notice.country?.toUpperCase() || ''}</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography variant="body1">{notice.from}</Typography>
        </Grid>
        <Grid item md={2}>
          <Typography variant="body1">{notice.to}</Typography>
        </Grid>
        <Grid item md={3}>
          <div className={getClassName(notice.finalStatus)}>
            <Typography variant="body1">{t(notice.finalStatus, { ns: 'viewReport' })}</Typography>
          </div>
        </Grid>
        <Grid item md={3}>
          <div className={getClassName(notice.certificateStatus)}>
            <Typography variant="body1">
              {t(notice.certificateStatus, { ns: 'viewReport' })}
            </Typography>
          </div>
        </Grid>
      </Grid>
    )
  return <div />
}

export default FinalNoticeRadioButton
