import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { Grid, Typography } from '@material-ui/core'
import { Input, Button, SelectInGrid } from 'components/atoms'
import { InputType } from 'utils/types'
import CompanyItem from './CompanyItem'

interface Props {
  type?: InputType
  name?: string
  helperText?: string
  disabled?: boolean
  label?: string
  // TODO
  options?: any
}

const FormItem: React.FC<Props> = ({
  type = 'default',
  name = '',
  helperText,
  disabled,
  label = '',
  options,
}) => {
  const { control } = useFormContext()

  const handleFieldChangeFromEvent: (
    event: React.ChangeEvent<HTMLInputElement>,
    onChange: (e: any) => void
  ) => void = ({ target: { value } }, onChange) => {
    onChange(value)
  }

  const textfieldComponent: (
    onChange: (e: any) => void,
    value: string,
    ref: React.RefCallback<any>
  ) => React.ReactNode = (onChange, value, ref) => (
    <Input
      ref={ref}
      value={value}
      onChange={e => handleFieldChangeFromEvent(e, onChange)}
      multiline
      rows={5}
      fullWidth
      type="textfield"
      label={label}
      disabled={disabled}
      helperText={helperText}
    />
  )
  const buttonComponent = () => (
    <Grid container spacing={0}>
      <Grid item xs={options?.xs || 4}>
        {type === 'leftButton' && (
          <Button type="submit" color="primary" disabled={disabled}>
            {label}
          </Button>
        )}
      </Grid>
      <Grid item xs={options?.xs || 4}>
        {type === 'rightButton' && (
          <Button type="submit" color="primary" disabled={disabled}>
            {label}
          </Button>
        )}
      </Grid>
    </Grid>
  )
  const buttonPositions = ['leftButton', 'rightButton']
  const inputComponent: (
    onChange: (e: any) => void,
    value: string,
    ref: React.RefCallback<any>
  ) => React.ReactNode = (onChange, value, ref) => (
    <Input
      ref={ref}
      value={value}
      onChange={e => handleFieldChangeFromEvent(e, onChange)}
      fullWidth
      type={type}
      label={label}
      disabled={disabled}
      helperText={helperText}
      {...options}
    />
  )
  const selectComponent: (
    onChange: (e: any) => void,
    value: string,
    ref: React.RefCallback<any>
  ) => React.ReactNode = (onChange, value, ref) => (
    <SelectInGrid
      ref={ref}
      value={value}
      onChange={onChange}
      fullWidth
      type={type}
      label={label}
      disabled={disabled}
      helperText={helperText}
      items={options?.items || []}
      {...options}
    />
  )
  const displayComponent: (
    onChange: (e: any) => void,
    value: string,
    ref: React.RefCallback<any>
  ) => React.ReactNode = (onChange, value, ref) => (
    <Input
      ref={ref}
      value={value}
      onChange={e => handleFieldChangeFromEvent(e, onChange)}
      fullWidth
      type="display"
      label={label}
      disabled
      helperText={helperText}
      {...options}
    />
  )
  const typographyElement = (value: string | React.ReactChild) => (
    <Typography variant="body1" {...options} color="textSecondary">
      {value}
    </Typography>
  )
  const companyBlock: (value: any) => React.ReactNode = value => (
    <CompanyItem label={label} value={value} />
  )

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <>
          {type === 'typography' && typographyElement(value)}
          {type === 'textfield' && textfieldComponent(onChange, value, ref)}
          {buttonPositions.includes(type) && buttonComponent()}
          {type === 'display' && displayComponent(onChange, value, ref)}
          {type === 'companyBlock' && companyBlock(value)}
          {type === 'select' && selectComponent(onChange, value, ref)}
          {type === 'default' && inputComponent(onChange, value, ref)}
        </>
      )}
    />
  )
}

export default React.memo(FormItem)
