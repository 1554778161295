import React, { LazyExoticComponent, Suspense } from 'react'
import { Route } from 'react-router-dom'
import { Loading } from '../components/atoms'
import { isLogin } from '../utils/helper'
import RedirectComponent from './RedirectComponent'

interface Props {
  component: LazyExoticComponent<any>
  restricted?: boolean
  [x: string]: any
}

const PublicRoute: React.FC<Props> = ({ component: Component, restricted, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLogin() && restricted ? (
        <Suspense fallback={<Loading />}>
          <RedirectComponent />
        </Suspense>
      ) : (
        <Suspense fallback={<Loading />}>
          <Component {...props} />
        </Suspense>
      )
    }
  />
)

export default PublicRoute
