import React from 'react'
import { ErrorMessage as HookFormErrorMessage } from '@hookform/error-message'
import { Typography } from '@material-ui/core'
import styled, { css } from 'styled-components'

const StyledError = styled(Typography)(
  props => css`
    color: ${props.theme.palette.error.main};
  `
)

interface Props {
  errors: object
  name: string
}

const ErrorMessage: React.FC<Props> = ({ errors, name }) => (
  <HookFormErrorMessage errors={errors} name={name} as={<StyledError />} />
)

export default ErrorMessage
