import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Breadcrumb } from './types'

export const defaultContextValue: IBreadcrumbsContext = {
  breadcrumbs: [],
  setBreadcrumbs: () => {},
}

const BreadcrumbsContext = React.createContext<IBreadcrumbsContext>(defaultContextValue)

export interface IBreadcrumbsContext {
  breadcrumbs?: Breadcrumb[]
  setBreadcrumbs: React.Dispatch<React.SetStateAction<Breadcrumb[] | undefined>>
}

export const BreadcrumbsProvider = ({ children }) => {
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[] | undefined>(
    defaultContextValue.breadcrumbs
  )
  const context = useMemo(
    () => ({
      breadcrumbs,
      setBreadcrumbs,
    }),
    [breadcrumbs, setBreadcrumbs]
  )

  return <BreadcrumbsContext.Provider value={context}>{children}</BreadcrumbsContext.Provider>
}
BreadcrumbsProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BreadcrumbsContext
