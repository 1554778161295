import React from 'react'
import { Tabs as MTabs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

interface Props {
  customVariant: string
  [x: string]: any
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: 'fit-content',
    '& .MuiTabs-scrollButtonsDesktop': { color: theme.palette.primary.main },
  },
  default: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.main,
  },
}))

const Tabs: React.FC<Props> = ({ customVariant, ...props }) => {
  const classes = useStyles()
  return (
    <MTabs
      TabIndicatorProps={{ className: classes[customVariant] }}
      className={clsx(classes.root)}
      {...props}
    />
  )
}

export default Tabs
