import { createTheme } from '@material-ui/core/styles'
import colors from './colors'
import overrides from './overrides'
import { Theme } from './types'

const {
  black,
  white,
  primaryColor,
  primaryDarker,
  secondaryColor,
  blue,
  green,
  textGrey,
  borderGrey,
  systemBlue,
  gSystemOrange,
  pickupSystemBlue,
  dualGreen,
  grey,
  brown,
  erdeGreen,
  greyDefault,
  erde,
  beize,
  pre,
  yellow,
  backgroundGrey,
  linkHover,
  red,
} = colors

const theme = createTheme({
  name: 'ERDE Meldeportal',
  overrides,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  props: {
    MuiButtonBase: {
      // The properties to apply
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
  shape: {
    borderRadius: 4,
    // TODO: no type in material-ui given
    // boxShadow: '0px 1px 5px rgba(0,0,0,0.3)',
  },
  palette: {
    common: { black, white },
    type: 'light',
    primary: {
      main: primaryColor,
      light: 'rgb(77, 171, 245)',
      dark: primaryDarker,
      contrastText: '#fff',
    },
    secondary: {
      main: secondaryColor,
      light: 'rgb(247, 51, 120)',
      dark: 'rgb(171, 0, 60)',
      contrastText: black,
    },
    error: { light: gSystemOrange, main: gSystemOrange, dark: gSystemOrange, contrastText: '#fff' },
    warning: {
      light: yellow,
      main: yellow,
      dark: yellow,
      contrastText: primaryColor,
    },
    info: { light: primaryColor, main: primaryColor, dark: primaryColor, contrastText: '#fff' },
    success: {
      light: primaryColor,
      main: primaryColor,
      dark: primaryColor,
      contrastText: primaryColor,
    },
    grey: {
      100: grey,
      200: textGrey,
      300: greyDefault,
      400: backgroundGrey,
      500: borderGrey,
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: textGrey,
      secondary: '#4b5563',
      disabled: greyDefault,
      hint: 'rgba(0, 0, 0, 0.38)',
    },
    divider: greyDefault,
    background: {
      paper: white,
      default: white,
    },
    action: {
      active: 'rgba(0, 0, 0, 0.54)',
      hover: 'rgba(0, 0, 0, 0.04)',
      hoverOpacity: 0.04,
      selected: 'rgba(0, 0, 0, 0.08)',
      selectedOpacity: 0.08,
      disabled: grey,
      disabledBackground: green,
      disabledOpacity: 0.38,
      focus: 'rgba(0, 0, 0, 0.12)',
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
    // @ts-ignore
    blue,
    textGrey,
    systemBlue,
    pickupSystemBlue,
    green,
    dualGreen,
    brown,
    erdeGreen,
    erde,
    beize,
    pre,
    gSystemOrange,
    backgroundGrey,
    linkHover,
    red,
  },
  typography: {
    htmlFontSize: 16,
    fontFamily:
      // eslint-disable-next-line max-len
      '"Asap", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif',
    fontSize: 16,
    fontWeightRegular: 400,
    fontWeightBold: 600,
    // @ts-ignore
    fontWeightBolder: 700,
    lineHeightSmall: '0.875rem',
    lineHeightNormal: '1.25rem',
    lineHeightBig: '1.625rem',
    lineHeightBigger: '2.375rem',
    lineHeightBiggest: '2.5rem',
    h1: {
      fontWeight: 600,
      fontSize: '1.5rem',
      lineHeight: 1.2,
      color: secondaryColor,
    },
    h2: {
      fontWeight: 600,
      fontSize: '1.125rem',
      lineHeight: 1.2,
      color: secondaryColor,
    },
    h3: {
      fontWeight: 700,
      fontSize: '0.875rem',
      lineHeight: 1.2,
      color: secondaryColor,
    },
    h4: {
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.2,
      color: erde,
    },
    caption: {
      fontWeight: 600,
      fontSize: '0.875rem',
      lineHeight: 1.2,
      color: textGrey,
    },
    // SubText (*required)
    body1: {
      fontSize: '0.875rem',
    },
    body2: {
      fontSize: '0.625rem',
      fontWeight: 600,
      lineHeight: 1.2,
      color: textGrey,
    },
  },
  fontFamily: '"Asap"',
  colorBlack: '#0d0c0e',
  fontSize: {
    small: '0.875rem', // 14px
    smaller: '0.75rem', // 12px
  },
  containerWidth: 1184,
}) as Theme

export default theme
