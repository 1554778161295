import React from 'react'
import { Link as RLink } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Theme } from 'utils/types'

interface Props {
  to: string
  children?: any
  className?: string
  bold?: boolean
}

const useStyles = makeStyles<Theme>(theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:visited': {
      color: theme.palette.secondary.main,
    },
    '&:hover': {
      color: theme.palette.linkHover,
    },
  },
  bold: {
    fontWeight: 'bold',
  },
}))

const Link: React.FC<Props> = ({ to, children, className, bold }) => {
  const classes = useStyles()
  return (
    <RLink to={to} className={clsx(classes.link, className, bold && classes.bold)}>
      {children}
    </RLink>
  )
}

export default Link
