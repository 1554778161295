import React from 'react'
import { Box } from '@material-ui/core'

interface Props {
  children: React.ReactChild
  index: number | string
  value: any
  [x: string]: any
}

const TabPanel: React.FC<Props> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`scrollable-auto-tabpanel-${index}`}
    aria-labelledby={`scrollable-auto-tab-${index}`}
    {...other}
  >
    {value === index && (
      <Box pt={4}>
        <div>{children}</div>
      </Box>
    )}
  </div>
)

export default TabPanel
