import React, { useCallback, useState, useContext, useEffect } from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Download } from 'assets/icons'
import { Button, Uploader, Text } from 'components/atoms'
import { MessageBox } from 'components/molecules'
import { UPLOAD_ADDITIONAL_AGREEMENT } from 'graphql/mutations'
import BreadcrumbsContext from 'utils/BreadcrumbsContext'
import CustomerContext from 'utils/CustomerContext'
import LocaleContext from 'utils/LocaleContext'
import { Breadcrumb, IMessageBox, Theme } from 'utils/types'

const useStyles = makeStyles<Theme>(theme => ({
  container: {
    [theme.breakpoints.up('md')]: {
      paddingRight: theme.spacing(8),
    },
  },
  paddingTop: {
    paddingTop: theme.spacing(4),
  },
  verticalPadding: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.linkHover,
    },
    '&:visited': {
      color: theme.palette.secondary.main,
    },
  },
  row: {
    paddingTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  text: {
    paddingLeft: theme.spacing(2),
  },
  tabletRow: {
    display: 'flex',
    flexDirection: 'column',
    '& > :first-child': {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      '& button': {
        textAlign: 'center',
      },
      '& > :first-child': {
        marginRight: theme.spacing(4),
        marginBottom: 0,
      },
    },
  },
}))

interface Params {
  contractId: string
}

const UploadAdditionalAgreement: React.FC = () => {
  const { t } = useTranslation('register')
  const classes = useStyles()
  const { contractId } = useParams<Params>()
  const { getCustomer, setCustomer } = useContext(CustomerContext)
  const { language } = useContext(LocaleContext)
  const customer = getCustomer(contractId)

  const [uploaderActive, setUploaderActive] = useState(false)
  const [blobId, setBlobId] = useState(null)
  const [mutationMessage, setMutationMessage] = useState<IMessageBox | undefined>()

  const { setBreadcrumbs } = useContext(BreadcrumbsContext)
  useEffect(() => {
    const breadcrumbs: Breadcrumb[] = [
      {
        link: '/',
        label: t('common:rigkBreadcrumb'),
      },
      {
        link: '/register',
        label: t('registerBreadcrumb'),
      },
      {
        link: '/confirmed-registration',
        label: t('confirmedRegistrationBreadcrumb'),
      },
    ]
    setBreadcrumbs(breadcrumbs)
  }, [t, setBreadcrumbs])

  const file = {
    name:
      language === 'de'
        ? 'Zusatzvereinbarung zur digitalen Mengenmeldung.pdf'
        : 'Additional Agreement.pdf',
    path: t('contracts:supplementaryAgreementLink'),
  }

  const onFilesUpload = useCallback(files => {
    setBlobId(files?.[0]?.signed_id)
  }, [])

  const [uploadAdditionAgreement] = useMutation(UPLOAD_ADDITIONAL_AGREEMENT, {
    onCompleted: data => {
      if (data.uploadAdditionalAgreement?.agreementNo) {
        setCustomer(data.uploadAdditionalAgreement)
        setMutationMessage({
          open: true,
          message: t('sucessfullyUploadedAdditionalAgreement'),
          variant: 'success',
        })
      } else {
        setMutationMessage({
          open: true,
          message: t('errorUploadedAdditionalAgreement'),
          variant: 'error',
        })
      }
    },
    onError: err => {
      console.error('err', err)
      setMutationMessage({
        open: true,
        message: t('errorUploadedAdditionalAgreement'),
        variant: 'error',
      })
    },
  })

  const submitAdditionalAgreement = () => {
    uploadAdditionAgreement({
      variables: {
        customerId: customer?.id,
        blobId,
      },
    })
  }

  return (
    <div className={classes.container}>
      <Typography variant="h1">{t('confirmedRegistrationTitle')}</Typography>
      <div className={classes.paddingTop}>
        <Text $color="textGrey">
          <Trans
            t={t}
            i18nKey="register:confirmedRegistrationIntro"
            components={{
              /* eslint-disable jsx-a11y/anchor-has-content */
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              li: <a className={classes.link} href="mailto:erde@raekoeve.de" />,
              /* eslint-enable jsx-a11y/anchor-has-content */
            }}
          />
        </Text>
      </div>
      <div className={classes.verticalPadding}>
        <Typography variant="caption">{t('pdfDownload')}*</Typography>
        <a
          href={file.path}
          download
          className={classes.link}
          onClick={() => setUploaderActive(true)}
        >
          <div className={classes.row}>
            <Download />
            <Typography className={classes.text}>{file.name}</Typography>
          </div>
        </a>
      </div>
      <div className={classes.verticalPadding}>
        <Typography variant="caption">{t('pdfUpload')}*</Typography>
        <Uploader
          maxFiles={1}
          noFileText={t('confirmedRegistrationUploaderWarning')}
          disabled={!uploaderActive}
          onFilesChanged={onFilesUpload}
        />
      </div>
      <div className={classes.tabletRow}>
        <Button disabled={!blobId} color="primary" onClick={submitAdditionalAgreement}>
          {t('common:submit')}
        </Button>
      </div>
      <MessageBox
        message={mutationMessage?.message}
        title={t('error')}
        open={!!mutationMessage?.open}
        variant={mutationMessage?.variant}
        onClose={() => setMutationMessage(undefined)}
      />
    </div>
  )
}

export default UploadAdditionalAgreement
