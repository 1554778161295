import { makeStyles } from '@material-ui/core/styles'
import { Theme } from 'utils/types'

const useStyles = makeStyles<Theme>(theme => ({
  button: {
    color: theme.palette.common.black,
    padding: '10px 45px',
    borderRadius: '2px',
    fontSize: '0.875rem',

    margin: '20px 0px 0px 10px',
  },
  half: {
    width: 'calc(50% - 11px)',
  },
  label: {
    color: theme.palette.textGrey,
  },
  linkLikeButton: {
    '& > *': {
      '& .MuiTypography-caption': {
        color: theme.palette.systemBlue,
      },
      minWidth: 0,
      margin: '0px 0px 35px 0px',
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        border: 'none',
      },
    },
  },
  input: {
    '& > *': {
      borderRadius: '2px',
    },
  },
  link: {
    lineHeight: '1.375rem',
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      '&:visited': {
        color: theme.palette.secondary.main,
      },
      '&:hover': {
        color: theme.palette.textGrey,
      },
    },
  },
}))
export default useStyles
