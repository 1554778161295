import React from 'react'
import { Button as MuiButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    flex: '0 0 auto',
    boxShadow: 'none',
  },
  buttonPrimary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.black,
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.common.black,
      boxShadow: 'none',
      borderColor: theme.palette.primary.dark,
    },
    '&:disabled': {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.text.disabled}`,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'inherit',
    },
    '&:focus': {
      boxShadow: `0 0 0 1px ${theme.palette.common.white}, 0 0 0 3px ${theme.palette.primary.main}`,
    },
  },
  buttonSecondary: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontWeight: 600,
    border: `1px solid ${theme.palette.secondary.main}`,
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.secondary.main,
      boxShadow: 'none',
      borderColor: theme.palette.secondary.main,
    },
    '&:disabled': {
      backgroundColor: theme.palette.text.disabled,
      color: theme.palette.common.white,
      border: `1px solid ${theme.palette.text.disabled}`,
      boxShadow: 'none',
      cursor: 'not-allowed',
      pointerEvents: 'inherit',
    },
    '&:focus': {
      border: '1px solid white',
      boxShadow: `0 0 0 1px ${theme.palette.common.white}, 0 0 0 3px ${theme.palette.secondary.main}`,
    },
  },
}))

interface Props {
  children: string | React.ReactNode
  color?: 'primary' | 'secondary'
  startIcon?: string | React.ReactNode
  endIcon?: string | React.ReactNode
  disabled?: boolean
  type?: string
  onClick?(e?: React.MouseEvent<HTMLElement>): void
  className?: string
  href?: string
}

const Button: React.FC<Props> = ({
  children,
  color,
  startIcon,
  endIcon,
  disabled,
  type,
  onClick,
  className,
  href = '',
}) => {
  const classes = useStyles()

  return (
    <MuiButton
      href={href}
      variant="contained"
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      className={clsx(
        className,
        classes.button,
        classes.buttonPrimary,
        color === 'secondary' ? classes.buttonSecondary : undefined
      )}
      type={type}
      onClick={onClick}
    >
      {children}
    </MuiButton>
  )
}

export default Button
