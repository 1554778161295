import React, { useContext } from 'react'
import NumberFormat from 'react-number-format'
import { makeStyles } from '@material-ui/core'
import LocaleContext from 'utils/LocaleContext'

interface Props {
  value?: string | number
  suffix?: string
  prefix?: string
}

const useStyles = makeStyles(() => ({
  font: { fontSize: '0.875rem' },
}))
const FormattedNumber: React.FC<Props> = ({ value, suffix, prefix }) => {
  const classes = useStyles()
  const { language } = useContext(LocaleContext)
  return (
    <NumberFormat
      value={value}
      thousandSeparator={language === 'de' ? '.' : ','}
      decimalSeparator={language === 'de' ? ',' : '.'}
      displayType="text"
      decimalScale={2}
      isNumericString
      fixedDecimalScale
      className={classes.font}
      suffix={suffix}
      prefix={prefix}
    />
  )
}

export default FormattedNumber
