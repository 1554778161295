import React from 'react'
import { Link } from 'react-router-dom'
import { Breadcrumbs as MBreadcrumbs } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import { Home } from 'assets/icons'
import { Breadcrumb, Theme } from 'utils/types'

interface Props {
  items?: Breadcrumb[]
}

const useStyles = makeStyles<Theme>(theme => ({
  link: {
    textDecoration: 'none',
    fontWeight: 600,
    color: theme.palette.textGrey,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  textGrey: {
    color: theme.palette.textGrey,
  },
  spacingBottom: {
    paddingBottom: theme.spacing(2),
  },
}))

const Breadcrumbs: React.FC<Props> = ({ items = [] }) => {
  const classes = useStyles()
  return (
    <MBreadcrumbs
      className={classes.spacingBottom}
      separator={<NavigateNextIcon className={classes.textGrey} fontSize="small" />}
      aria-label="breadcrumb"
    >
      <Link className={classes.link} to="/">
        <Home />
        HOME
      </Link>
      {items?.map(item =>
        item.link ? (
          <Link key={item.link} to={item.link} className={classes.link}>
            {item.icon}
            {item.label}
          </Link>
        ) : (
          <span key={item.label?.toString()} className={classes.link}>
            {item.icon}
            {item.label}
          </span>
        )
      )}
    </MBreadcrumbs>
  )
}

export default Breadcrumbs
