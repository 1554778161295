import React, { useContext } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from 'components/atoms'
import LocaleContext from 'utils/LocaleContext'
import { FieldPosition, Position } from 'utils/types'

interface Props {
  row: FieldPosition
  index: number
  defaultValues?: Position
}

const useStyles = makeStyles(theme => ({
  colorBlack: {
    '&.Mui-disabled,.MuiInputBase-input': {
      color: `${theme.palette.text.secondary} !important`,
    },
    '& > .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.grey[500]}`,
    },
  },
  maxWidth100: {
    maxWidth: '100%',
    paddingBottom: '20px',
  },
  spacingTop: {
    marginTop: '18px',
  },
  smallSpacingTop: {
    marginTop: '10px',
  },
}))

const FormItem: React.FC<Props> = ({ row, index, defaultValues }) => {
  const { language } = useContext(LocaleContext)
  const { t } = useTranslation('common')
  const classes = useStyles()
  const { control, setValue, watch } = useFormContext()
  const amount = watch(`positions[${index}].amount`)

  if (defaultValues?.rowId) {
    setValue(`positions[${index}].id`, defaultValues.rowId, { shouldDirty: true })
  }

  const { title, titleEn, position } = row
  setValue(`positions[${index}].position`, position, { shouldDirty: true })
  setValue(`positions[${index}].title`, title, {
    shouldDirty: true,
  })

  return amount ? (
    <Grid container spacing={2} className={classes.maxWidth100}>
      <Grid item xs={2} sm={1} lg={1} className={classes.spacingTop}>
        <Typography color="textSecondary">{position}</Typography>
      </Grid>
      <Grid item xs={10} sm={5} lg={5} className={classes.spacingTop}>
        <Typography color="textSecondary">{language === 'de' ? title : titleEn}</Typography>
      </Grid>
      <Grid item xs={6} sm={3} lg={3}>
        <Controller
          name={`positions[${index}].amount`}
          defaultValue={defaultValues?.amount}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              className={classes.colorBlack}
              type="number"
              placeholder={t('amountInKilo')}
              small
              disabled
            />
          )}
        />
      </Grid>
      <Grid item xs={6} sm={3} lg={3}>
        <Controller
          name={`positions[${index}].amountForeign`}
          defaultValue={defaultValues?.amountForeign}
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              className={classes.colorBlack}
              type="number"
              placeholder={t('amountInKilo')}
              small
              disabled
            />
          )}
        />
      </Grid>
    </Grid>
  ) : null
}

export default FormItem
