import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import { Box, InputAdornment, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import de from 'date-fns/locale/de'
import Wrapper from '../DatePickerWrapper'
// TODO:  check why that breaks tests
import 'react-datepicker/dist/react-datepicker.css'

interface Props {
  startDate?: Date | null
  endDate?: Date | null
  onStartDateChange?(date?: Date | null): void
  onEndDateChange?(date?: Date | null): void
}

const useStyles = makeStyles(theme => ({
  rightBorder: {
    '& .MuiOutlinedInput-input': {
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
  },
}))

const DateRangePicker: React.FC<Props> = ({
  startDate: propStartDate = new Date(),
  endDate: propEndDate = new Date(),
  onStartDateChange,
  onEndDateChange,
  ...rest
}) => {
  // eslint-disable-next-line global-require
  require('react-datepicker/dist/react-datepicker.css')
  const classes = useStyles()
  const [startDate, setStartDate] = useState<Date | undefined | null>(propStartDate)
  const [endDate, setEndDate] = useState(propEndDate)
  useEffect(() => {
    if (startDate !== propStartDate) setStartDate(propStartDate)
    if (endDate !== propEndDate) setEndDate(propEndDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propStartDate, propEndDate])
  return (
    <Wrapper>
      <DatePicker
        selected={startDate}
        onChange={(date: Date | null) => {
          if (typeof onStartDateChange === 'function') {
            onStartDateChange(date)
          } else {
            setStartDate(date)
          }
        }}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        dateFormat="dd.MM.yyyy"
        customInput={
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            className={classes.rightBorder}
            label="Start"
            variant="outlined"
            {...rest}
          />
        }
        locale={de}
        monthsShown={2}
        showPopperArrow={false}
      />
      <Box m={1} />
      <DatePicker
        selected={endDate}
        onChange={date => {
          if (typeof onEndDateChange === 'function') {
            onEndDateChange(date)
          } else {
            setEndDate(date)
          }
        }}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        minDate={startDate}
        dateFormat="dd.MM.yyyy"
        customInput={
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarTodayIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            className={classes.rightBorder}
            label="Ende"
            variant="outlined"
            {...rest}
          />
        }
        calendarClassName="material-calendar"
        locale={de}
        monthsShown={2}
        showPopperArrow={false}
      />
    </Wrapper>
  )
}

export default DateRangePicker
