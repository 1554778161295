import React, { Suspense } from 'react'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from 'utils/types'
import { Loading, Footer } from '../components/atoms'
import { Breadcrumbs, ContactBox } from '../wrappers'
import Header from './Header'

interface Props {
  children: React.ReactChild | React.ReactChild[]
}

const useStyles = makeStyles<Theme>(theme => ({
  content: {
    minHeight: '80vh',
    width: '100%',
    maxWidth: theme.containerWidth,
    margin: 'auto',
    padding: theme.spacing(1),
  },
  children: {
    paddingTop: theme.spacing(4),
    height: '100%',
  },
  paddingTop: {
    paddingTop: theme.spacing(4),
  },
}))

const LoggedOutLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles()
  return (
    <div>
      <Suspense fallback={<Loading />}>
        <Header />
      </Suspense>
      <div className={classes.content}>
        <Suspense fallback={<Loading />}>
          <Breadcrumbs />
        </Suspense>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={8}>
            <Suspense fallback={<Loading />}>
              <div className={classes.children}>{children}</div>
            </Suspense>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Suspense fallback={<Loading />}>
              <div className={classes.paddingTop}>
                <ContactBox orientation="column" />
              </div>
            </Suspense>
          </Grid>
        </Grid>
      </div>
      <Suspense fallback={<Loading />}>
        <Footer />
      </Suspense>
    </div>
  )
}

export default LoggedOutLayout
