import CacheBuster from 'react-cache-buster'
import ReactDOM from 'react-dom'
import { ApolloProvider } from '@apollo/client'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import * as Sentry from '@sentry/browser'
import {
  ExtraErrorData as ExtraErrorDataIntegration,
  HttpClient as HttpClientIntegration,
} from '@sentry/integrations'
import * as SentryReact from '@sentry/react'
import { ThemeProvider } from 'styled-components'
import packageJson from '../package.json'
import client from './client'
import Localization from './Localization'
import reportWebVitals from './reportWebVitals'
import Router from './routes/Router'
import { BreadcrumbsProvider } from './utils/BreadcrumbsContext'
import { CustomerProvider } from './utils/CustomerContext'
import GlobalStyle from './utils/GlobalStyle'
import theme from './utils/theme'
import { UserContextProvider } from './utils/UserContext'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    new SentryReact.BrowserTracing({
      traceFetch: true,
      traceXHR: true,
      tracePropagationTargets: [/erde-report.de/, /localhost/],
    }),
    new Sentry.Replay({
      // Additional SDK configuration goes in here, for example:
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [/erde-report.de/, /localhost/],
    }),
    new ExtraErrorDataIntegration({
      // Limit of how deep the object serializer should go. Anything deeper than limit will
      // be replaced with standard Node.js REPL notation of [Object], [Array], [Function] or
      // a primitive value. Defaults to 3.
      depth: 3,
    }),
    new HttpClientIntegration(),
  ],
  release: packageJson.version,
  tracesSampleRate: 0.4,
  // This sets the sample rate to be 30%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.5,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV,
  tracePropagationTargets: [/erde-report.de/, /localhost/],
})

// TODO: create error page
const style = {
  display: 'flex',
  flexDirection: 'column' as 'column',
  margin: 'auto',
  height: '100vh',
  width: '100vw',
  textAlign: 'center' as 'center',
  justifyContent: 'center',
}
const ErrorDisplay = () => (
  <h1 style={style}>An uncaught error appeared. Please contact the development team.</h1>
)

const { version } = packageJson

ReactDOM.render(
  <CacheBuster
    currentVersion={version}
    isEnabled // If false, the library is disabled.
    isVerboseMode // If true, the library writes verbose logs to console.
    loadingComponent={null} // If not pass, nothing appears at the time of new version check.
  >
    <SentryReact.ErrorBoundary fallback={ErrorDisplay}>
      <UserContextProvider>
        <ApolloProvider client={client}>
          <Localization>
            <MuiThemeProvider theme={theme}>
              <ThemeProvider theme={theme}>
                <BreadcrumbsProvider>
                  <CustomerProvider>
                    <Router />
                  </CustomerProvider>
                  <CssBaseline />
                  <GlobalStyle />
                </BreadcrumbsProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </Localization>
        </ApolloProvider>
      </UserContextProvider>
    </SentryReact.ErrorBoundary>
  </CacheBuster>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
