import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Theme } from 'utils/types'

type IImage = {
  src?: string
  alt?: string
}
interface ILink {
  key: string
  title: string
  href: string
  img?: IImage | null
}

const useStyles = makeStyles<Theme>(theme => ({
  footer: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'center',
    padding: theme.spacing(4),
    paddingTop: 80,
    '& a': {
      color: theme.palette.secondary.main,
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      marginRight: 10,
      marginLeft: 10,
      '&:visited': {
        color: theme.palette.secondary.main,
      },
      '&:hover': {
        color: theme.palette.textGrey,
      },
    },
    '& img': {
      marginRight: theme.spacing(1),
    },
  },
  items: {
    width: '100%',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      '& a': {
        marginBottom: 10,
      },
      '& #imprint': { order: 0 },
      '& #privacy': { order: 1 },
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      maxWidth: theme.containerWidth,
      justifyContent: 'center',
    },
  },
}))

const Footer = () => {
  const classes = useStyles()

  const { t } = useTranslation('common')
  const linkObj: ILink[] = [
    {
      key: 'imprint',
      title: t('imprint'),
      href: t('imprintLink'),
      img: null,
    },
    {
      key: 'privacy',
      title: t('privacy'),
      href: t('privacyLink'),
      img: null,
    },
  ]

  return (
    <div className={classes.footer}>
      <div className={classes.items}>
        {linkObj.map(link => (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={link?.href}
            key={link?.key}
            id={link?.key}
          >
            {link?.img && <img alt={link.img?.alt || ''} src={link.img?.src || ''} width={50} />}
            <Typography>{link?.title}</Typography>
          </a>
        ))}
      </div>
    </div>
  )
}

export default Footer
