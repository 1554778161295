import React from 'react'
import { useLocation } from 'react-router'
import { makeStyles } from '@material-ui/core/styles'
import LogoERDE from 'assets/images/erde-logo.svg'
import backgroundImage from 'assets/images/topbar-background.jpeg'
import clsx from 'clsx'
import { LanguagePicker } from 'components/atoms'
import { Theme } from 'utils/types'
import Container from './Container'
import Logo from './Logo'
import LogoWrapper from './LogoWrapper'
import NavItem from './NavItem'
import StyledA from './StyledA'
import StyledButton from './StyledButton'

interface MenuItem {
  to: string
  href?: string
  title: string
  onClick?(e?: any): void
}

interface Props {
  // TODO
  menuItems?: MenuItem[]
}

const useStyles = makeStyles<Theme>(theme => ({
  languagepicker: {
    height: '55px',
    '& > .MuiSelect-selectMenu': {
      padding: '0px 10px 0 10px',
      fontSize: '1.0625rem',
      display: 'flex',
      alignItems: 'center',
      fontWeight: 700,
    },
    '& >.MuiSelect-iconOutlined': {
      right: '0px',
      width: 8,
    },
  },
  label: { padding: 0, margin: 0 },
  row: {
    display: 'flex',
    flexDirection: 'row',
  },
  spaceBetween: {
    maxWidth: theme.containerWidth,
    width: '100%',
    justifyContent: 'space-between',
  },
  backgroundImage: {
    aspectRatio: '16/4',
    objectFit: 'cover',
    position: 'relative',
  },
}))

const TopBar: React.FC<Props> = ({ menuItems }) => {
  const location = useLocation()
  const classes = useStyles()
  const buildMenuItemComponent = item => {
    let returnComponent = (
      <NavItem to={item.to} key={item.title} $selected={location?.pathname?.includes(item?.to)}>
        {item.title}
      </NavItem>
    )
    if (item.href) {
      returnComponent = (
        <StyledA
          tabIndex={-1}
          href={item.href}
          key={item.title}
          role="button"
          target="_blank"
          rel="noreferrer"
        >
          {item.title}
        </StyledA>
      )
    }
    if (item.onClick) {
      returnComponent = (
        <StyledButton key={item.title} onClick={item.onClick}>
          {item.title}
        </StyledButton>
      )
    }
    return returnComponent
  }
  return (
    <div>
      <Container>
        <div className={clsx(classes.row, classes.spaceBetween)}>
          <div className={classes.row}>
            <LogoWrapper>
              <Logo src={LogoERDE} alt="ERDE Logo" />
            </LogoWrapper>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {menuItems?.map(item => buildMenuItemComponent(item))}

            <LanguagePicker selectClass={classes.languagepicker} labelClass={classes.label} />
          </div>
        </div>
      </Container>
      <img
        src={backgroundImage}
        alt="ERDE Headerbild"
        width="100%"
        className={classes.backgroundImage}
      />
    </div>
  )
}

export default TopBar
