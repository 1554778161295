import React, { useState, useEffect } from 'react'
import { Pagination as MPagination } from '@material-ui/lab'

interface Props {
  page: number
  setPage(page: number): void
  countPages: number
}

const Pagination: React.FC<Props> = ({
  page: propPage = 1,
  setPage: propSetPage,
  countPages = 1,
}) => {
  const [page, setPage] = useState(1)
  useEffect(() => {
    setPage(propPage)
  }, [propPage, setPage])

  const handleChange: (x: any, newPage: number) => void = (_, newPage) => {
    setPage(newPage)
    propSetPage?.(newPage)
  }
  return (
    <MPagination
      count={countPages}
      variant="outlined"
      shape="rounded"
      page={page}
      onChange={handleChange}
    />
  )
}

export default Pagination
