import React from 'react'
import { LocaleProvider } from 'utils/LocaleContext'

interface Props {
  children: React.ReactChild
}

const Localization: React.FC<Props> = ({ children }) => <LocaleProvider>{children}</LocaleProvider>

export default Localization
