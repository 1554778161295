import React from 'react'
import { Grid, GridSize } from '@material-ui/core'
import { InputType } from 'utils/types'
import FormItem from '../FormItem'

interface ElementOptions {
  small?: boolean
  labelLeft?: boolean
  labelClassName?: string
  error?: boolean
  helperText?: string
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize
  xl?: GridSize
}

export interface InputGroupElement {
  disabled?: boolean
  label: string
  name: string
  xs?: GridSize
  sm?: GridSize
  md?: GridSize
  lg?: GridSize
  xl?: GridSize
  type?: InputType
  options?: ElementOptions
}

interface Props {
  elements: InputGroupElement[]
}

const InputGroup: React.FC<Props> = ({ elements }) => (
  <Grid container spacing={2}>
    {elements.map(({ sm, md, lg, xl, xs, ...elementProps }) => (
      <Grid item key={elementProps.name} xs={xs || 12} sm={sm} md={md} lg={lg} xl={xl}>
        <FormItem {...elementProps} />
      </Grid>
    ))}
  </Grid>
)

export default React.memo(InputGroup)
