import React from 'react'
import { Grid, Typography, Box } from '@material-ui/core'

interface IValue {
  value?: number | string
  label?: string
}

interface Props {
  label: string
  value: IValue[]
}

const CompanyItem: React.FC<Props> = ({ value: items, label }) => (
  <Grid container direction="column" spacing={1}>
    <Grid item>
      <Typography variant="h2">{label}</Typography>
    </Grid>
    {items.map(item => {
      const { value, label: itemLabel } = item
      return (
        <Grid container item spacing={1}>
          <Grid item>
            <Typography variant="body1">
              <Box sx={{ fontWeight: 'bold' }}>{itemLabel}</Box>
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1">{value}</Typography>
          </Grid>
        </Grid>
      )
    })}
  </Grid>
)

export default CompanyItem
