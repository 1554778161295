import React, { useContext } from 'react'
import { Breadcrumbs } from '../components/atoms'
import BreadcrumbsContext from '../utils/BreadcrumbsContext'

const BreadcrumbsWithContext: React.FC = () => {
  const { breadcrumbs } = useContext(BreadcrumbsContext)
  return <Breadcrumbs items={breadcrumbs} />
}

export default BreadcrumbsWithContext
