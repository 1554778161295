import React from 'react'
import { AppBar as MAppBar } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

interface Props {
  children: React.ReactChild
  backgroundColor?: string
  shadow?: boolean
  position?: 'absolute' | 'fixed' | 'relative' | 'static' | 'sticky'
}

const AppBar: React.FC<Props> = ({
  children,
  backgroundColor = 'white',
  shadow,
  position = 'relative',
}) => {
  const Appb = withStyles(() => ({
    root: {
      ...(!shadow && { boxShadow: 'none' }),
      backgroundColor,
    },
  }))(MAppBar)
  return <Appb position={position}>{children}</Appb>
}

export default AppBar
