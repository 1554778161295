import { useRef } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { useHistory } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import client from '../client'
import config from '../config'
import { ME_QUERY } from '../graphql/queries'
import { getExpiry, getToken, getUid, getClient, clearUserData, setTimer, setUser } from './helper'

const TimeOut = () => {
  const history = useHistory()
  const timeOutRef = useRef<ReturnType<typeof setTimeout> | undefined>()

  const storeUser = (data: any) => {
    setUser(data.me)
  }

  const logout = () => {
    fetch(`${config.apollo.baseUrl}/auth/sign_out`, {
      method: 'DELETE',
      headers: {
        'access-token': getToken(),
        uid: getUid(),
        client: getClient(),
      },
    })
      .then(() => {
        clearUserData(client)
        history.push('/logout')
      })
      .catch(() => {
        clearUserData(client)
        history.push('/logout')
      })
  }

  const [fetchNewRefreshToken] = useLazyQuery(ME_QUERY, {
    fetchPolicy: 'network-only',
    onCompleted: storeUser,
    onError: () => {
      setTimer(
        JSON.stringify({
          last_expiry: moment.unix(Number(getExpiry())).toLocaleString(),
          date: moment().toLocaleString(),
          message: 'logged out by me_query',
        })
      )
      logout()
    },
  })

  const getTimeLeft = () => moment.unix(Number(getExpiry())).diff(moment())

  const handleOnIdle = () => {
    console.debug('idle')
    timeOutRef.current = setTimeout(() => {
      logout()
      setTimer(
        JSON.stringify({
          last_expiry: moment.unix(Number(getExpiry())).toLocaleString(),
          date: moment().toLocaleString(),
          message: 'loggout by setTimeout',
        })
      )
      console.debug('logging out')
    }, getTimeLeft() - 10000)
  }

  const handleActive = () => {
    console.debug('active')
    if (timeOutRef?.current) {
      clearTimeout(timeOutRef.current)
      console.debug('cleanup')
    }
    if (getTimeLeft() < 10000) {
      setTimer(
        JSON.stringify({
          last_expiry: moment.unix(Number(getExpiry())).toLocaleString(),
          date: moment().toLocaleString(),
          message: 'logout by handleActive',
        })
      )
      logout()
    }
  }
  const handleOnAction = (event: Event) => {
    if (event.type === 'mousedown' || event.type === 'keydown') {
      console.debug(event.type)
      fetchNewRefreshToken()
    }
  }

  useIdleTimer({
    timeout: 1000 * 3,
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    onActive: handleActive,
    debounce: 2000,
  })
  return null
}

export default TimeOut
