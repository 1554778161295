import React, { useState, useRef, useEffect } from 'react'
import { Tooltip, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import colors from 'utils/colors'

interface Props {
  children: React.ReactChild
  color?: string
  className?: string
  onClick?(e: any): void
}

const OverflowTip: React.FC<Props> = ({ children, color = 'white', className, onClick }) => {
  const useStyles = makeStyles(() => ({
    ellipsis: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  }))
  const classes = useStyles()
  const [overflowed, setOverflowed] = useState(false)
  const textElement = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (textElement.current) {
      setOverflowed(textElement.current.scrollWidth > textElement.current.clientWidth)
    }
  }, [])

  return (
    <Tooltip title={children} disableHoverListener={!overflowed} onClick={onClick}>
      <div
        ref={textElement}
        className={clsx(classes.ellipsis, className)}
        style={{ color: colors[color] ?? colors.textGrey }}
      >
        {children}
      </div>
    </Tooltip>
  )
}

export default OverflowTip
