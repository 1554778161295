import { gql } from '@apollo/client'

export const ME_QUERY = gql`
  query me {
    me {
      id
      email
      active
    }
  }
`

export const PROFILE_QUERY = gql`
  query profile {
    me {
      id
      firstName
      lastName
      phone
      locale
    }
  }
`

export const USERS_QUERY = gql`
  query users {
    users {
      collection {
        id
        name
        firstName
        lastName
        email
        role
        isConfirmed
        isLocked
      }
    }
  }
`

export const CUSTOMERS_SIDEBAR_QUERY = gql`
  query customers_sidebar_query {
    customers {
      additionalAddressInformationCustomer
      additionalAddressInformationInvoice
      additionalAgreementByteSize
      additionalAgreementContentType
      additionalAgreementFilename
      additionalAgreementUrl
      agreementNo
      acceptsDigitalInvoices
      cityCustomer
      cityInvoice
      companyCustomer
      companyNameInvoice
      contact1Email
      contact1JobTitle
      contact1Language
      contact1Name
      contact1Phone
      contact1Salutation
      contact2Email
      contact2JobTitle
      contact2Language
      contact2Name
      contact2Phone
      contact2Salutation
      countryCustomer
      countryInvoice
      createdAt
      departmentCustomer
      emailInvoice
      emailUser
      id
      openAdvancedNotices
      streetCustomer
      streetInvoice
      updatedAt
      uploadedAdditionalAgreement
      uploads {
        id
        attachmentUrl
        category
        attachmentFilename
      }
      validAdditionalAgreement
      vatId
      zipCustomer
      zipInvoice
    }
  }
`

export const CUSTOMER_BANK_DATA_QUERY = gql`
  query customers_bank_data_query($id: ID!) {
    customer(id: $id) {
      id
      paymentReceiver
      isPaymentDataValid
      iban
      bic
    }
  }
`

export const HEADER_ADVANCED_NOTICES_QUERY = gql`
  query headerAdvancedNotices($customerId: ID!, $filter: HeaderAdvancedNoticeFilter) {
    headerAdvancedNotices(customerId: $customerId, filter: $filter) {
      collection {
        id
        advancedStatus
        advancedDateStart
        advancedDateEnd
        advancedYear
        country
        advancedNotes
        transmittedAt
        downloadLink
        customer {
          id
          agreementNo
        }
        positions {
          id
          position
          title
          amount
          amountForeign
          noticeId
          noticeType
        }
        uploads {
          id
          attachmentUrl
          category
          attachmentFilename
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export const HEADER_FINAL_NOTICES_QUERY = gql`
  query headerFinalNotices($customerId: ID!, $filter: HeaderFinalNoticeFilter) {
    headerFinalNotices(customerId: $customerId, filter: $filter) {
      collection {
        id
        certificateStatus
        country
        downloadLink
        finalStatus
        finalDateStart
        finalDateEnd
        finalYear
        transmittedAt
        finalNotes
        customer {
          id
          agreementNo
        }
        orderNos {
          id
          category {
            id
            titleDe
            titleEn
          }
          value
        }
        positions {
          id
          position
          title
          amount
          amountForeign
          noticeId
          noticeType
        }
        uploads {
          id
          attachmentUrl
          category
          attachmentFilename
        }
      }
      metadata {
        totalCount
      }
    }
  }
`

export const ARTICLES_QUERY = gql`
  query articles($year: String!) {
    articles(year: $year) {
      position
      title
      titleEn
    }
  }
`

export const INVOICES_QUERY = gql`
  query invoices($filter: InvoiceFilter, $page: Int, $limit: Int, $customerId: ID!) {
    invoices(filter: $filter, page: $page, limit: $limit, customerId: $customerId) {
      metadata {
        totalPages
        currentPage
      }
      collection {
        id
        year
        invoiceDate
        invoiceNumber
        contractType
        documentUrl
        createdAt
        updatedAt
        customer {
          id
          agreementNo
        }
      }
    }
  }
`

export const ORDER_NO_CATEGORIES_QUERY = gql`
  query orderNoCategories {
    orderNoCategories {
      metadata {
        totalPages
        currentPage
      }
      collection {
        id
        titleDe
        titleEn
      }
    }
  }
`
