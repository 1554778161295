import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from 'components/atoms'
import FormItem from './DisplayFormItem'

interface Props {
  // TODO
  fields: any[]
  startIndex?: number
  sumTitle?: string
}

const useStyles = makeStyles(theme => ({
  bold: { fontWeight: 600 },
  label: {
    padding: 0,
  },
  colorBlack: {
    '&.Mui-disabled,.MuiInputBase-input': {
      color: `${theme.palette.text.secondary} !important`,
    },
    '& > .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.grey[500]}`,
    },
  },
  maxWidth100: {
    maxWidth: '100%',
  },
}))

const sumReducer: (
  accumulator: number,
  index: number,
  watch: (name: string) => string,
  name: string
) => number = (a, index, watch, name) => {
  const returnValString = watch(`positions[${index}].${name}`)
  let returnVal = parseFloat(returnValString)
  returnVal = Number.isNaN(returnVal) ? 0 : returnVal
  return a + returnVal
}

const DisplayFormGroup: React.FC<Props> = ({ fields, sumTitle, startIndex = 0 }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const { watch } = useFormContext()

  const renderSum = () => {
    const sumAmount = fields?.reduce(
      (a, _, index) => sumReducer(a, index + startIndex, watch, 'amount'),
      0
    )
    const sumAmountForeign = fields?.reduce(
      (a, _, index) => sumReducer(a, index + startIndex, watch, 'amountForeign'),
      0
    )

    return (
      sumAmount + sumAmountForeign !== 0 && (
        <Grid
          container
          alignItems="center"
          spacing={2}
          direction="row"
          className={classes.maxWidth100}
        >
          <Grid item xs={6} sm={6} lg={6}>
            <Typography color="textSecondary" className={classes.bold}>
              {sumTitle}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3} lg={3}>
            <div className={classes.colorBlack}>
              <Input
                name="sum"
                value={sumAmount}
                type="number"
                placeholder={t('amountInKilo')}
                small
                labelClassName={classes.label}
                disabled
                className={classes.colorBlack}
                decimalScale={0}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={3} lg={3}>
            <div className={classes.colorBlack}>
              <Input
                name="sum"
                value={sumAmountForeign}
                type="number"
                placeholder={t('amountInKilo')}
                small
                labelClassName={classes.label}
                disabled
                className={classes.colorBlack}
                decimalScale={0}
              />
            </div>
          </Grid>
        </Grid>
      )
    )
  }
  return (
    <>
      {fields?.map((field, index) => (
        <FormItem key={field.position} row={field} index={index + startIndex} />
      ))}
      {sumTitle && renderSum()}
    </>
  )
}

export default DisplayFormGroup
