import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { IMenuItem } from 'components/atoms'
import { Customer } from 'utils/types'
import { SideBar } from '../components/organisms'
import { CUSTOMERS_SIDEBAR_QUERY } from '../graphql/queries'
import CustomerContext from '../utils/CustomerContext'
import { useLogout } from '../utils/hooks'

const SideBarWithData: React.FC = () => {
  const { customers, setCustomers } = useContext(CustomerContext)
  const { t } = useTranslation('sidebar')
  useQuery(CUSTOMERS_SIDEBAR_QUERY, {
    onCompleted: data => {
      setCustomers(data?.customers)
    },
  })
  const logout = useLogout()

  const generateCustomerEntry: (customer: Customer) => IMenuItem = customer => ({
    title: customer.companyCustomer,
    to: `/contracts/contract/${customer.id}`,
    subItems: [
      {
        title: t('finalNotice'),
        to: `/contracts/contract/${customer.id}/final-notice?step=0`,
      },
      {
        title: t('advancedNotice'),
        to: `/contracts/contract/${customer.id}/advanced-notice`,
      },
      {
        title: t('viewReports'),
        to: `/contracts/contract/${customer.id}/all-reports`,
      },
    ],
  })

  const items: IMenuItem[] = [
    {
      title: t('yourContracts'),
      to: (customers?.length || 0) > 0 ? `/contracts/contract/${customers?.[0]?.id}` : '/profile',
      notLinked: true,
    },
    ...(customers?.map(generateCustomerEntry) || []),
    {
      title: undefined,
    },
    {
      title: t('viewInvoices'),
      to: '/invoices',
    },
    {
      title: t('manageData'),
      to: '/profile',
    },
    {
      title: t('logout'),
      to: '/logout',
      onClick: logout,
    },
  ]
  return (
    <div>
      <SideBar items={items} />
    </div>
  )
}

export default SideBarWithData
