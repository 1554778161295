import React from 'react'
import Snackbar, { SnackbarProps } from '../../atoms/Snackbar'

interface Props extends SnackbarProps {
  horizontal?: 'left' | 'center' | 'right'
  message?: string
  open: boolean
  autoHideDuration?: number
  title?: string
  onClose?(): void
  onClick?(e: React.MouseEvent<HTMLElement>): void
  variant?: 'error' | 'warning' | 'info' | 'success'
  vertical?: 'top' | 'bottom'
}

// NOTE: to use multiple MessageBoxes we should use something like notistack
const MessageBox: React.FC<Props> = ({
  horizontal = 'center',
  message,
  open,
  variant = 'info',
  vertical = 'bottom',
  autoHideDuration,
  onClose,
  onClick,
  ...rest
}) => (
  <Snackbar
    open={open}
    onClick={onClick}
    onClose={onClose}
    anchorOrigin={{ horizontal, vertical }}
    autoHideDuration={autoHideDuration ?? 5000}
    severity={variant}
    message={message}
    {...rest}
  />
)

export default MessageBox
