import styled, { css } from 'styled-components'

interface Props {
  padding?: string | number
}

const Col = styled.div<Props>`
  display: flex;
  flex-direction: column;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
`

export default Col
