import React from 'react'
import { Typography, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { QuestionMarkOutlined } from 'assets/icons'
import clsx from 'clsx'
import Text from 'components/atoms/Text'

interface OptionsOption {
  $bold?: boolean
  $bolder?: boolean
  $center?: boolean
  $color?: string
  $disabled?: boolean
  $italic?: boolean
  fontSize?: string | number
  fontWeight?: string
  lineHeight?: string | number
}

interface Option {
  titleClassName?: string
  tooltipTitleClassName?: string
  tooltipBodyClassName?: string
  tooltipTextOptions?: OptionsOption
}

interface Props {
  title: string
  options?: Option
  tooltipTitle?: string
  tooltipMessage: string
}

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: theme.spacing(1),
  },
  noMaxWitdh: {
    maxWidth: 'none',
  },
  tooltip: {
    backgroundColor: 'white',

    boxShadow: '0 6px 16px -8px black',
  },
  marginLeft: {
    marginLeft: 20,
  },
  marginBottom: { marginBottom: 20 },
  titleContent: {
    display: 'flex',
    alignItems: 'center',
  },
  padding: {
    padding: '10px',
  },
}))

const InfoTitle: React.FC<Props> = ({ title, tooltipTitle, tooltipMessage, options }) => {
  const classes = useStyles()
  return (
    <Typography className={clsx(classes.title, options?.titleClassName)} variant="h3">
      <div className={classes.titleContent}>
        {title}

        <Tooltip
          title={
            <div className={clsx(classes.padding, options?.tooltipBodyClassName)}>
              <Typography
                variant="h2"
                color="textSecondary"
                className={options?.tooltipTitleClassName}
              >
                {tooltipTitle}
              </Typography>
              <Text fontSize="0.875rem" $color="textGrey" {...options?.tooltipTextOptions}>
                {tooltipMessage}
              </Text>
            </div>
          }
          className={clsx(classes.noMaxWitdh, classes.marginLeft)}
          classes={{
            tooltip: classes.tooltip,
          }}
        >
          <QuestionMarkOutlined height="20px" />
        </Tooltip>
      </div>
    </Typography>
  )
}

export default InfoTitle
