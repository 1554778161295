import React from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Delete } from 'assets/icons'
import { Button, Select, Input } from 'components/atoms'
import { Theme } from 'utils/types'

interface Props {
  name: string
  addAdditionalTitle: string
  addTitle: string
  control: any
  // TODO
  items: any[]
}

const useStyles = makeStyles<Theme>(theme => ({
  fullWidth: {
    width: '100%',
  },
  input: {
    '& .MuiOutlinedInput-input': {
      padding: '15px',
    },
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  my: {
    marginTop: 20,
    marginBottom: 20,
  },
  button: {
    cursor: 'pointer',
    color: theme.palette.red,
    paddingTop: '27px',
    '&:hover': {
      color: theme.palette.linkHover,
    },
  },
}))

const OrderNoSelection: React.FC<Props> = ({
  control,
  name,
  addAdditionalTitle,
  addTitle,
  items,
}) => {
  const classes = useStyles()
  const { t } = useTranslation('finalNotice')
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  })
  return (
    <div className={classes.fullWidth}>
      {fields.map((f, index) => (
        <Grid spacing={2} container key={f.id}>
          <Grid item xs={12} md={5}>
            <Controller
              name={`${name}.${index}.categoryId`}
              control={control}
              render={({ field }) => (
                <Select minWidth="100%" {...field} label={t('category')} items={items} />
              )}
            />
          </Grid>
          <Grid item xs={10} md={5}>
            <Controller
              name={`${name}.${index}.value`}
              control={control}
              render={({ field }) => (
                <Input
                  className={classes.input}
                  fullWidth
                  label={t('orderNo')}
                  type="default"
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={2} md={2} className={classes.flexCenter}>
            <div
              role="button"
              tabIndex={0}
              className={classes.button}
              onKeyDown={() => remove(index)}
              onClick={() => remove(index)}
            >
              <Delete />
            </div>
          </Grid>
        </Grid>
      ))}
      <Button
        className={classes.my}
        onClick={() => append({ categoryId: null, value: null, id: null })}
      >
        {fields.length > 0 ? addAdditionalTitle : addTitle}
      </Button>
    </div>
  )
}

export default OrderNoSelection
