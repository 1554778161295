import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { Download, UploadOrange } from 'assets/icons'
import clsx from 'clsx'
import { debounce } from 'lodash'
import colors from 'utils/colors'
import { useFetchExport } from 'utils/hooks'

interface Props {
  name?: string
  url?: string
  isPublicFile?: boolean
  onClick?(e?: any): void
  labelClassName?: string
}

const useStyles = makeStyles(theme => ({
  disabled: {
    pointerEvents: 'none',
  },
  divStyle: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    color: colors.secondaryColor,
    textDecoration: 'none !important',
  },
  linkLikeButton: {
    '& > *': {
      '& .MuiTypography-h3': {
        color: 'theme.palette.secondary.main',
      },
      minWidth: 0,
      margin: '0px 0px 35px 0px',
      backgroundColor: 'transparent',
      border: 'none',
      padding: 0,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        border: 'none',
      },
    },
  },
  orange: { color: colors.gSystemOrange },
  marginLeft: { marginLeft: 5 },
}))

const DownloadComponent: React.FC<Props> = ({
  url,
  name,
  labelClassName,
  onClick,
  isPublicFile,
}) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const [load, setLoad] = useState(false)
  const [{ error, loading }, doFetch] = useFetchExport({ headers: {} })

  if (error) console.error(error)

  const throttledFetch = debounce(
    () => {
      doFetch(url)
      if (!loading) setLoad(false)
    },
    500,
    { trailing: true, maxWait: 5000, leading: false }
  )

  return url && name ? (
    <div
      key={name}
      role="button"
      className={clsx(classes.divStyle, load ? classes.disabled : null)}
      onKeyDown={() => {}}
      tabIndex={-1}
      onClick={e => {
        if (!isPublicFile) {
          e.stopPropagation()
          setLoad(true)
          throttledFetch()
        }
        onClick?.()
      }}
    >
      <Download />
      <div className={clsx(classes.marginLeft, labelClassName)}>{name}</div>
    </div>
  ) : (
    <div className={clsx(classes.divStyle, classes.orange)}>
      <UploadOrange />
      <div className={clsx(classes.marginLeft, labelClassName)}>{t('noDocument')}</div>
    </div>
  )
}

export default DownloadComponent
