import React, { useState, useEffect } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ArrowDown } from 'assets/icons'
import clsx from 'clsx'
import { Theme } from 'utils/types'

interface SubItem {
  title: string
  to: string
}

export interface IMenuItem {
  onClick?(e: any): void
  to?: string
  notLinked?: boolean
  title?: string
  subItems?: SubItem[]
}

interface Props {
  item: IMenuItem
}

const useStyles = makeStyles<Theme>(theme => ({
  navContainer: {
    '& > div': {
      textDecoration: 'none',
      color: theme.palette.textGrey,
      cursor: 'pointer',
    },
    '& > div.active': {
      color: theme.palette.secondary.main,
    },
    '& a': {
      textDecoration: 'none',
      color: theme.palette.textGrey,
    },
    '& a.active': {
      color: theme.palette.secondary.main,
    },
  },
  menuItem: {
    fontWeight: 600,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
  },
  childContainer: {
    paddingLeft: theme.spacing(2),
  },
  arrow: {
    marginLeft: theme.spacing(2),
    marginRight: '5px',
    verticalAlign: 'middle',
    transform: 'translateY(50%)',
  },
  arrowRotated: {
    transform: 'rotate(180deg)',
  },
}))

const MenuItem: React.FC<Props> = ({ item }) => {
  const [open, setOpen] = useState(false)
  const classes = useStyles()
  const { to = '', subItems, title, onClick, notLinked } = item
  const location = useLocation()
  useEffect(() => {
    if (subItems?.filter(child => child.to.includes(location.pathname)).length) setOpen(true)
    else setOpen(false)
  }, [location.pathname, subItems])
  const renderWithChildren = () => (
    <>
      <NavLink
        exact
        to={to}
        tabIndex={0}
        role="button"
        onKeyDown={() => setOpen(!open)}
        onClick={() => setOpen(!open)}
      >
        <Typography
          color={location.pathname.includes(to) ? 'secondary' : 'inherit'}
          className={classes.menuItem}
        >
          {title}
          <ArrowDown className={clsx(classes.arrow, open ? classes.arrowRotated : undefined)} />
        </Typography>
      </NavLink>
      {open ? (
        <div className={classes.childContainer}>
          {subItems?.map(child => (
            <NavLink key={child.to} to={child.to} exact>
              <Typography color="inherit" className={classes.menuItem}>
                {child.title}
              </Typography>
            </NavLink>
          ))}
        </div>
      ) : null}
    </>
  )

  const renderWithoutChildren = () => {
    if (onClick)
      return (
        <div tabIndex={-1} onKeyPress={() => {}} role="button" onClick={onClick}>
          <Typography
            color={location.pathname.includes(to) ? 'secondary' : 'inherit'}
            className={classes.menuItem}
          >
            {title}
          </Typography>
        </div>
      )
    if (notLinked)
      return (
        <Typography color="secondary" className={classes.menuItem}>
          {title}
        </Typography>
      )
    return (
      <NavLink to={to} exact>
        <Typography
          color={location.pathname.includes(to) ? 'secondary' : 'inherit'}
          className={classes.menuItem}
        >
          {title}
        </Typography>
      </NavLink>
    )
  }

  return (
    <div className={classes.navContainer}>
      {item?.subItems?.length ? renderWithChildren() : renderWithoutChildren()}
    </div>
  )
}

export default MenuItem
