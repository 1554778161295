import React, { useEffect, useState } from 'react'
import { Checkbox, FormControlLabel, FormHelperText } from '@material-ui/core'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'

interface Props {
  className?: string
  checked?: boolean
  onChange?(checked: boolean, name: string): void
  name?: string
  label?: string | React.ReactNode
  helperText?: string
  error?: boolean
}

const CheckBox = React.forwardRef<HTMLButtonElement, Props>(
  (
    { className, checked: propChecked, onChange, name = 'CheckBox', label, helperText, error },
    ref
  ) => {
    const [checked, setChecked] = useState(propChecked)
    useEffect(() => {
      setChecked(propChecked)
    }, [propChecked])

    const handleChange = e => {
      setChecked(e.target.checked)
      if (onChange) onChange(e.target.checked, e.target.name)
    }

    return (
      <>
        <FormControlLabel
          className={className}
          control={
            <Checkbox
              ref={ref}
              checked={checked}
              color="secondary"
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              onChange={handleChange}
              name={name}
            />
          }
          label={label}
        />
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </>
    )
  }
)

export default CheckBox
