const env = process.env.REACT_APP_ENV as string
const backendPort = process.env.REACT_APP_BACKEND_PORT || 3000
const devIP = process.env.REACT_APP_DEV_IP || 'localhost'

const appConfig = {
  apollo: {
    networkInterface: {
      development: `http://${devIP}:${backendPort}/graphql`,
      staging: 'https://api-staging.erde-report.de/graphql',
      production: 'https://api.erde-report.de/graphql',
    }[env],
    baseUrl: {
      development: `http://${devIP}:${backendPort}`,
      staging: 'https://api-staging.erde-report.de',
      production: 'https://api.erde-report.de',
    }[env],
  },
}

export default appConfig
