import React, { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import { useLazyQuery } from '@apollo/client'
import { Loading } from '../components/atoms'
import { CUSTOMERS_SIDEBAR_QUERY } from '../graphql/queries'

const NO_CUSTOMER_FOUND = 'no-customer-found'

const RedirectComponent: React.FC = () => {
  const [id, setId] = useState<string | undefined>()
  const [fetchCustomer] = useLazyQuery(CUSTOMERS_SIDEBAR_QUERY, {
    onCompleted: res => {
      const { customers } = res || {}
      if (customers?.length) {
        const [{ id: customer }] = customers
        setId(customer)
      } else {
        setId(NO_CUSTOMER_FOUND)
      }
    },
  })
  useEffect(() => {
    fetchCustomer()
  }, [fetchCustomer])
  if (!id) return <Loading />
  if (id === NO_CUSTOMER_FOUND) return <Redirect to="/profile" />
  return <Redirect to={`/contracts/contract/${id}`} />
}
export default RedirectComponent
