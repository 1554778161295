import { lazy } from 'react'

const routes = [
  {
    path: '/',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazy(() => import('../views/Home')),
  },
  {
    path: '/rechtliches/impressum',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazy(() => import('../views/Legal/Imprint')),
  },
  {
    path: '/rechtliches/datenschutz',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazy(() => import('../views/Legal/Privacy')),
  },
  {
    path: '/rechtliches/nutzungsbedingungen',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazy(() => import('../views/Legal/TermsOfUse')),
  },
  {
    path: '/legal/imprint',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazy(() => import('../views/Legal/Imprint')),
  },
  {
    path: '/legal/privacy',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazy(() => import('../views/Legal/Privacy')),
  },
  {
    path: '/legal/terms-of-use',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazy(() => import('../views/Legal/TermsOfUse')),
  },
  {
    path: '/login',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazy(() => import('../views/auth/Login')),
  },
  {
    path: '/resend-confirmation-mail',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazy(() => import('../views/auth/ResendConfirmationMail')),
  },
  {
    path: '/forgot-password',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazy(() => import('../views/auth/ForgotPassword')),
  },
  {
    path: '/reset-password',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazy(() => import('../views/auth/ResetPassword')),
  },
  {
    path: '/register',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazy(() => import('../views/auth/Register')),
  },
  {
    path: '/confirm-registration',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazy(() => import('../views/auth/Register/ConfirmRegistration')),
  },
  {
    path: '/confirmed',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazy(() => import('../views/auth/Register/ConfirmedRegistrationSuccess')),
  },
  {
    path: '/confirmed-registration/success',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazy(() => import('../views/auth/Register/ConfirmedRegistrationSuccess')),
  },
  {
    path: '/confirmed-registration',
    exact: true,
    isPublic: true,
    restricted: true,
    Component: lazy(() => import('../views/auth/Register/ConfirmedRegistration')),
  },
  {
    path: '/logout',
    exact: true,
    isPublic: true,
    restricted: false,
    Component: lazy(() => import('../views/auth/Logout')),
  },
  {
    path: '/inactive',
    exact: true,
    isPublic: false,
    Component: lazy(() => import('../views/InactiveUser')),
  },
  {
    path: '/contracts/contract/:contractId',
    exact: true,
    isPublic: false,
    Component: lazy(() => import('../views/Contract')),
  },
  {
    path: '/contracts/contract/:contractId/:reportType',
    exact: true,
    isPublic: false,
    Component: lazy(() => import('../views/Contract/Detail')),
  },
  {
    path: '/invoices',
    exact: true,
    isPublic: false,
    Component: lazy(() => import('../views/Invoices')),
  },
  {
    path: '/profile',
    exact: true,
    isPublic: false,
    Component: lazy(() => import('../views/Profile')),
  },
  {
    path: '*',
    isPublic: true,
    Component: lazy(() => import('../views/Route404')),
  },
]

export default routes
