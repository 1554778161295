import React from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Text } from 'components/atoms'
import { ContactInfo } from 'components/molecules'
import { Theme } from 'utils/types'

interface Contact {
  name: string
  email: string
  phone: string
  jobTitle: string
  imgSrc: string
  imgAlt: string
  imgOptions: any
}

interface Props {
  contacts: Contact[]
  orientation?: 'row' | 'column'
}

const useStyles = makeStyles<Theme>(theme => ({
  text: {
    paddingTop: theme.spacing(2),
    color: theme.palette.textGrey,
  },
  grid: {
    '& > div': {
      paddingTop: theme.spacing(2),
    },
  },
}))

const ContactBox: React.FC<Props> = ({ contacts, orientation = 'row' }) => {
  const classes = useStyles()
  const { t } = useTranslation('common')
  return (
    <Grid container direction={orientation} className={classes.grid} spacing={6}>
      <Grid item md={orientation === 'row' ? 4 : 12} sm={12}>
        <Typography variant="h2">{t('youHaveQuestions')}</Typography>
        <Text className={classes.text}>{t('questionsInfoText')}</Text>
      </Grid>
      {contacts?.map(({ name, email, phone, jobTitle, imgSrc, imgAlt, imgOptions }) => (
        <Grid key={name} item md={orientation === 'row' ? 4 : 12} sm={12}>
          <ContactInfo
            key={name}
            name={name}
            email={email}
            phone={phone}
            jobTitle={jobTitle}
            imgSrc={imgSrc}
            imgAlt={imgAlt}
            imgOptions={imgOptions}
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default ContactBox
