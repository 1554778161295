import React from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { DataGrid, GridColDef } from '@material-ui/data-grid'
import { Info } from 'assets/icons'
import { Theme } from 'utils/types'

export interface ITableColumn extends GridColDef {
  sortable?: boolean
  filterable?: boolean
  headerName?: string
  type?: string
  renderCell?(e?: any): React.ReactNode | string
}

interface Props {
  columns: ITableColumn[]
  rows: any[]
  isCellEditable?: boolean
  loading?: boolean
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {
    '& .MuiDataGrid-columnSeparator': {
      color: 'white',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      color: theme.palette.text.primary,
      fontSize: theme.fontSize.small,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      padding: 0,
    },
    '& .MuiDataGrid-cell': {
      fontSize: theme.fontSize.small,
      fontWeight: 'normal',
    },
  },
  orange: { color: theme.palette.error.light },
  emptyRows: {
    display: 'flex',
    paddingTop: 70,
    paddingLeft: 40,
    alignItems: 'center',
  },
  rightSpacing: { marginRight: 20 },
}))

const NoRowsOverlay = () => {
  const { t } = useTranslation('common')
  const classes = useStyles()

  return (
    <div className={classes.emptyRows}>
      <Info className={classes.rightSpacing} />
      <Typography variant="h2" className={classes.orange}>
        {t('emptyTable')}
      </Typography>
    </div>
  )
}

const Table: React.FC<Props> = ({ columns, rows = [], isCellEditable, loading }) => {
  const classes = useStyles()

  return (
    <DataGrid
      components={{
        NoRowsOverlay,
      }}
      disableColumnMenu
      autoHeight
      isCellEditable={() => !!isCellEditable}
      hideFooterPagination
      className={classes.root}
      columns={columns}
      rows={rows}
      disableSelectionOnClick
      loading={loading}
    />
  )
}

export default Table
