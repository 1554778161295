export const contractsCount = (count: number) => [...Array(count || 1).keys()]

export const errorHandler: (errorObject: object, name: string, i?: number) => string = (
  errorObject,
  name,
  i
) => {
  if (!Object.keys(errorObject).length) return null
  if (!Number.isNaN(i) && i !== undefined) {
    const [group, item] = name.split('.')
    return errorObject?.[group]?.[i]?.[item]?.message || ''
  }
  if (name) {
    return errorObject?.[name]?.message
  }
  return 'common:error'
}
