export interface Locale {
  moment: string
  name: string
}

export interface Locales {
  de: Locale
  en: Locale
}
export const locales: Locales = {
  de: {
    moment: 'de',
    name: 'de',
  },
  en: {
    moment: 'en-gb',
    name: 'en',
  },
}

const loadLocale = async (locale: string) => {
  if (locales[locale]) {
    await import(`moment/locale/${locales[locale].moment}`)
    return { moment: locales[locale].moment }
  }
  return loadLocale('en')
}

export default loadLocale
