import React, { useState, useMemo, useCallback } from 'react'
import { initReactI18next } from 'react-i18next'
import PropTypes from 'prop-types'
import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import moment from 'moment'
import { storeLanguage, getStoredLanguage } from './helper'
import loadLocale, { locales, Locale } from './locales'

interface Language {
  value: string
  label: string
}

const availableLanguages: Language[] = [
  {
    value: 'de',
    label: 'DE',
  },
  {
    value: 'en',
    label: 'EN',
  },
]

const defaultLanguageKey = getStoredLanguage() || 'de'

export const defaultContextValue: () => ILocaleContext = () => ({
  language: locales[defaultLanguageKey],
  setLanguage: () => '',
  availableLanguages,
})

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: defaultLanguageKey,
    fallbackLng: 'de',
    ns: [
      'common',
      'auth',
      'adancedNotice',
      'finalNotice',
      'login',
      'register',
      'contracts',
      'profile',
      'sidebar',
      'table',
      'translation',
      'viewReport',
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
  })

interface ILocaleContext {
  language: string
  setLanguage(newLanguage: string): void
  availableLanguages: Language[]
}

const LocaleContext = React.createContext(defaultContextValue())

export const LocaleProvider = ({ children }) => {
  const [language, setStateLanguage] = useState<string>(defaultLanguageKey)
  const setLanguage = useCallback(
    (newLang: string) => {
      setStateLanguage(newLang)
      storeLanguage(newLang)
      i18n.changeLanguage(locales[newLang]?.name)
    },
    [setStateLanguage]
  )
  const context = useMemo(
    () => ({
      language,
      setLanguage,
      availableLanguages,
    }),
    [language, setLanguage]
  )

  loadLocale(language).then((localeObject: Locale) => {
    moment.locale(localeObject?.moment)
  })
  return <LocaleContext.Provider value={context}>{children}</LocaleContext.Provider>
}
LocaleProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default LocaleContext
