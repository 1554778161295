import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format'
import { FormLabel, Grid, TextField } from '@material-ui/core'
import { InputType } from 'utils/types'

interface Props {
  disabled?: boolean
  fullWidth?: boolean
  label?: string
  multiline?: boolean
  onChange?(e: any): void
  rows?: number
  showPassword?: boolean
  small?: boolean
  type?: InputType
  className?: string
  labelClassName?: string
  value?: string | number
  defaultValue?: string | number
  name?: string
  error?: string
  autoFocus?: boolean
  helperText?: string
  required?: boolean
  placeholder?: string
  noLabel?: boolean
  labelLeft?: boolean
  InputProps?: any
  decimalScale?: number
  allowNegative?: boolean
}

interface CustomNumberProps {
  inputRef: React.RefObject<HTMLElement>
  name: string
  onChange(newValue: number): void
}

const CustomNumber: React.FC<CustomNumberProps> = ({
  inputRef,
  onChange: onChangeValue,
  ...other
}) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={({ floatValue }) => {
      onChangeValue(floatValue || 0)
    }}
    thousandSeparator="."
    decimalSeparator=","
  />
)

const Input = React.forwardRef<React.FC, Props>(
  (
    {
      disabled,
      fullWidth,
      label,
      multiline,
      onChange,
      rows,
      showPassword,
      small,
      type = 'default',
      className,
      labelClassName,
      value,
      defaultValue,
      name,
      error,
      autoFocus,
      helperText,
      required,
      placeholder,
      noLabel,
      labelLeft,
      InputProps,
      decimalScale,
      allowNegative,
    },
    ref
  ) => {
    const [visible, setVisible] = useState<boolean | undefined>(false)

    useEffect(() => {
      setVisible(showPassword)
    }, [showPassword])

    type Variant = 'outlined'
    type Size = 'small' | 'medium' | undefined
    const defaultFieldProps = {
      variant: 'outlined' as Variant,
      disabled,
      autoFocus,
      name,
      onChange,
      size: (small ? 'small' : 'medium') as Size,
      value,
      defaultValue,
      fullWidth,
      multiline,
      rows,
      className,
      error: !!error,
      helperText: helperText || '',
      placeholder,
    }

    const numberFieldProps = {
      InputProps: {
        inputComponent: CustomNumber,
        ...InputProps,
        inputProps: {
          decimalScale,
          allowNegative,
        },
      },
    }

    const moneyFieldProps = {
      InputProps: {
        endAdornment: '€',
        inputComponent: CustomNumber,
        ...InputProps,
        inputProps: {
          decimalScale,
          allowNegative,
        },
      },
    }

    const passwordFieldProps = { type: visible ? 'test' : 'password' }

    let textFieldComponent = <TextField inputRef={ref} {...defaultFieldProps} />

    if (type === 'number') {
      textFieldComponent = <TextField inputRef={ref} {...defaultFieldProps} {...numberFieldProps} />
    }

    if (type === 'money') {
      textFieldComponent = <TextField inputRef={ref} {...defaultFieldProps} {...moneyFieldProps} />
    }

    if (type === 'password') {
      textFieldComponent = (
        <TextField inputRef={ref} {...defaultFieldProps} {...passwordFieldProps} />
      )
    }

    return (
      <Grid
        container
        {...(labelLeft
          ? { direction: 'row', alignItems: 'center', spacing: 2 }
          : { direction: 'column' })}
      >
        {(!noLabel || !label?.length) && (
          <Grid item {...(labelLeft ? { xs: 12, md: 5 } : {})}>
            <FormLabel
              className={labelClassName}
              component="label"
              {...(labelLeft && { style: { padding: 0 } })}
            >
              {label}
              {required && '*'}
            </FormLabel>
          </Grid>
        )}
        <Grid item {...(labelLeft ? { xs: 12, md: 7 } : {})}>
          {textFieldComponent}
        </Grid>
      </Grid>
    )
  }
)

export default Input
