import styled, { css } from 'styled-components'

interface Props {
  $noSpacing?: boolean
}

const Divider = styled.hr<Props>(
  props => css`
    border-top: 1px solid ${props.theme.palette?.primary?.main};
    ${props.$noSpacing &&
    css`
      margin: 0;
      padding: 0;
    `}
  `
)

export default Divider
