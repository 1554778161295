import React from 'react'
import { makeStyles } from '@material-ui/core'
import Dieter from '../assets/images/dieter.jpg'
import Lina from '../assets/images/lina.jpg'
import { ContactBox } from '../components/organisms'

interface Props {
  orientation: 'column' | 'row'
}

const useStyles = makeStyles(() => ({
  root: {
    width: '100px',
    height: '100px',
    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
  },
  imgKoeve: {
    objectFit: 'cover',
    objectPosition: '0px 0px',
  },
  imgLina: {
    width: '160px ',
    height: '160px ',
    objectPosition: '-20px 22px',
  },
}))
const ContactBoxWithData: React.FC<Props> = ({ orientation }) => {
  const classes = useStyles()
  const contacts = [
    {
      name: 'Rechtsanwalt Dieter Koeve',
      email: 'erde@raekoeve.de',
      phone: '+49 6172 9021-26',
      jobTitle: 'Treuhänder ERDE',
      imgSrc: Dieter,
      imgAlt: 'Dieter Koeve',
      imgOptions: {
        classes: { img: classes.imgKoeve, root: classes.root },
      },
    },
    {
      name: 'Lina Harisch',
      email: 'erde@raekoeve.de',
      phone: '+49 6172 9021-27',
      jobTitle: 'Key Accountant ERDE',
      imgSrc: Lina,
      imgOptions: {
        classes: { img: classes.imgLina, root: classes.root },
      },
      imgAlt: 'Lina Harisch',
    },
  ]
  return <ContactBox orientation={orientation} contacts={contacts} />
}
export default ContactBoxWithData
