import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import { useLocationChange } from 'utils/hooks'
import { AppBar, Tab, Tabs, TabPanel } from './components'

export interface ITab {
  key: string
  label: string
  content: string | React.ReactChild
}

interface Props {
  variant?: 'default' | 'card'
  tabs: ITab[]
  scrollable?: boolean
  isUrlParameter?: boolean
  defaultActive?: string
}

const a11yProps = (index: number) => ({
  id: `scrollable-auto-tab-${index}`,
  'aria-controls': `scrollable-auto-tabpanel-${index}`,
})
const TabbedContent: React.FC<Props> = ({
  tabs,
  scrollable,
  defaultActive,
  variant = 'default',
  isUrlParameter,
}) => {
  const [value, setValue] = useState<string | null>(defaultActive || tabs?.[0]?.key || '0')
  const history = useHistory()
  const { pathname } = useLocation()
  const handleChange: (event: any, newValue: string) => void = (_, newValue) => {
    if (isUrlParameter) {
      history.push({
        pathname,
        search: queryString.stringify({ tab: newValue }),
      })
    }
    setValue(newValue)
  }

  const routeToTab = (location: Location) => {
    if (isUrlParameter && location?.search) {
      const { tab } = queryString.parse(location.search)
      if (value !== tab) {
        setValue(tab as string | null)
      }
    }
  }

  useLocationChange(routeToTab)

  return (
    <>
      <AppBar>
        <Tabs
          value={value}
          variant={scrollable ? 'scrollable' : 'standard'}
          customVariant={variant}
          color="default"
          indicatorColor={variant === 'card' ? 'transparent' : undefined}
          onChange={handleChange}
        >
          {tabs?.map((tab, i) => (
            <Tab
              variant={variant}
              key={tab.key}
              value={tab.key || i}
              label={tab.label}
              {...a11yProps(i)}
            />
          ))}
        </Tabs>
      </AppBar>
      {tabs?.map((tab, i) => (
        <TabPanel key={tab.key || i} value={value} index={tab.key || i}>
          {tab.content}
        </TabPanel>
      ))}
    </>
  )
}

export default React.memo(TabbedContent)
