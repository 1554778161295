import styled, { css } from 'styled-components'

interface Props {
  width?: string | number
  height?: string | number
}

const RoundImage = styled.img<Props>(
  props => css`
  width: ${props.width || '100px'};
  height: $props.height || '100px'};
  border-radius: 50%;
`
)

export default RoundImage
