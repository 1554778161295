import React, { useContext, useMemo } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from 'components/atoms'
import LocaleContext from 'utils/LocaleContext'
import { FieldPosition, Position } from 'utils/types'

interface Props {
  row: FieldPosition
  index: number
  data?: Position[]
}

const useStyles = makeStyles(() => ({
  spacingBottom: {
    paddingBottom: '20px',
    maxWidth: '100%',
  },
  spacingTop: {
    marginTop: '18px',
  },
}))

const FormItem: React.FC<Props> = ({ row, index, data }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const { language } = useContext(LocaleContext)
  const { control, trigger, setValue } = useFormContext()
  const defaultValues = useMemo(() => {
    const {
      amount,
      amountForeign,
      id: rowId,
      noticeType,
      position,
      title,
    } = data?.find(pc => pc.position === row.position) || {}

    return rowId
      ? {
          amount,
          amountForeign,
          rowId,
          noticeType,
          position,
          title,
        }
      : undefined
  }, [data, row])

  if (defaultValues?.rowId) {
    setValue(`positions[${index}].id`, defaultValues.rowId, { shouldDirty: true })
  }
  const { title, titleEn, position } = row
  setValue(`positions[${index}].position`, position, { shouldDirty: true })
  setValue(`positions[${index}].title`, title, {
    shouldDirty: true,
  })

  const handleChange: (value: any, onChange: (val: any) => void) => void = (value, onChange) => {
    onChange(value)
    trigger(`positions[${index}]`)
  }
  return (
    <Grid container spacing={2} className={classes.spacingBottom}>
      <Grid item xs={2} sm={1} lg={1} className={classes.spacingTop}>
        <Typography color="textSecondary">{position}</Typography>
      </Grid>
      <Grid item xs={10} sm={5} lg={5} className={classes.spacingTop}>
        <Typography color="textSecondary">{language === 'de' ? title : titleEn}</Typography>
      </Grid>
      <Grid item xs={12} sm={3} lg={3}>
        <Controller
          name={`positions[${index}].amount`}
          defaultValue={defaultValues?.amount}
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <Input
              {...field}
              type="number"
              onChange={value => {
                handleChange(value, onChange)
                if (value === 0) {
                  setValue(`positions[${index}].amount`, '')
                }
              }}
              placeholder={t('amountInKilo')}
              small
              allowNegative={false}
              decimalScale={0}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3} lg={3}>
        <Controller
          name={`positions[${index}].amountForeign`}
          defaultValue={defaultValues?.amountForeign}
          control={control}
          render={({ field: { onChange, ...field } }) => (
            <Input
              {...field}
              type="number"
              onChange={value => {
                handleChange(value, onChange)
                if (value === 0) {
                  setValue(`positions[${index}].amountForeign`, '')
                }
              }}
              placeholder={t('amountInKilo')}
              small
              allowNegative={false}
              decimalScale={0}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

export default FormItem
