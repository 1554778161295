import moment from 'moment'

// DEBUG
// export const isLogin = () => true

const LANGUAGE_KEY = 'rigk.language'
export const storeLanguage = (lang: any) => localStorage.setItem(LANGUAGE_KEY, lang)
export const getStoredLanguage = () => localStorage.getItem(LANGUAGE_KEY)

export const TOKEN_KEY = 'rigk.access-token'
export const setToken = (token: any) => localStorage.setItem(TOKEN_KEY, token)
export const getToken = () => localStorage.getItem(TOKEN_KEY) || ''
export const removeToken = () => localStorage.removeItem(TOKEN_KEY)

export const UID_KEY = 'rigk.uid'
export const setUid = (uid: any) => localStorage.setItem(UID_KEY, uid)
export const getUid = () => localStorage.getItem(UID_KEY) || ''
export const removeUid = () => localStorage.removeItem(UID_KEY)

export const CLIENT_KEY = 'rigk.client'
export const setClient = (cl: any) => localStorage.setItem(CLIENT_KEY, cl)
export const getClient = () => localStorage.getItem(CLIENT_KEY) || ''
export const removeClient = () => localStorage.removeItem(CLIENT_KEY)

export const EXPIRY_KEY = 'rigk.expiry'
export const setExpiry = (expiry: any) => localStorage.setItem(EXPIRY_KEY, expiry)
export const getExpiry = () => localStorage.getItem(EXPIRY_KEY) || ''
export const removeExpiry = () => localStorage.removeItem(EXPIRY_KEY)

export const USER_ID_KEY = 'rigk.user.id'
export const setUserId = (id: any) => localStorage.setItem(USER_ID_KEY, id)
export const getUserId = () => localStorage.getItem(USER_ID_KEY) || ''
export const removeUserId = () => localStorage.removeItem(USER_ID_KEY)

export const USER_KEY = 'rigk.user'
export const setUser = (user: any) => localStorage.setItem(USER_KEY, JSON.stringify(user))
export const getUser = () => localStorage.getItem(USER_KEY)
export const removeUser = () => localStorage.removeItem(USER_KEY)

export const TIME_KEY = 'rigk.logout.time'
export const setTimer = (time: any) => localStorage.setItem(TIME_KEY, time)
export const getTimer = (time: any) => localStorage.getItem(TIME_KEY) || time
export const removeTimer = () => localStorage.removeItem(TIME_KEY)

export const clearUserData = (client?: any) => {
  removeToken()
  removeUid()
  removeClient()
  removeExpiry()
  removeUserId()
  removeUser()
  client?.resetStore?.()
  client?.cache?.reset?.()
}

export const formatNumber = value =>
  Number(value || '0')
    .toFixed(2)
    .replace('.', ',')
export const formatCurrency = value => `${formatNumber(value)} €`

const browserLanguage = () => {
  if (typeof navigator !== 'undefined' && /prerender/i.test(navigator.userAgent)) {
    return 'de'
  }
  const lang = navigator.language
  return lang.split('-')[0]
}

export const language = () => getStoredLanguage() || browserLanguage()

export const getYearDiff = (start: string | Date, end: string | Date) =>
  moment(end).year() - moment(start).year()

export const isLogin = () => !!getToken()
export const isActive = () => JSON.parse(getUser() || '{}')?.active
