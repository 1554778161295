import styled from 'styled-components'
import colors from 'utils/colors'
import { bgColor } from 'utils/stylesHelper'

interface Props {
  $color?: string
}

const Box = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px;
  min-height: 330px;
  ${props => props.$color && bgColor(props.$color)};
  border-top: 5px solid ${colors.textGrey};
`

export default Box
