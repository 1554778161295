import React from 'react'
import { Select as MSelect } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

interface Props {
  maxWidth?: string | number
  minWidth?: string | number
  [x: string]: any
}

const CustomSelect: React.FC<Props> = props => {
  const { minWidth = 100, maxWidth = 180 } = props
  const Select = withStyles(() => ({
    select: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth,
      minWidth,
      padding: '15px 10px',
      '&:focus': {
        backgroundColor: 'transparent',
      },
    },
  }))(MSelect)

  return <Select {...props} />
}

export default CustomSelect
