import React from 'react'
import LoggedInLayout from './LoggedInLayout'
import LoggedOutLayout from './LoggedOutLayout'

interface Props {
  children: React.ReactChild | React.ReactChild[]
  logged?: boolean
}

const MainLayout: React.FC<Props> = ({ children, logged }) =>
  logged ? (
    <LoggedInLayout>{children}</LoggedInLayout>
  ) : (
    <LoggedOutLayout>{children}</LoggedOutLayout>
  )

export default MainLayout
