import React from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Input } from 'components/atoms'
import { Theme } from 'utils/types'
import * as Yup from 'yup'

interface Props {
  onSubmit(e?: any): void
  disabled?: boolean
  title: string
  buttonText?: string
}

const useStyles = makeStyles<Theme>(theme => ({
  button: {
    color: theme.palette.common.black,
    padding: '10px 45px',
    borderRadius: '2px',
    fontSize: '0.875rem',
  },
  input: {
    width: '65%',
  },
  label: {
    color: theme.palette.textGrey,
  },
}))

const EmailForm: React.FC<Props> = ({ onSubmit, disabled, title, buttonText = 'Submit' }) => {
  const classes = useStyles()
  const { t } = useTranslation('auth')

  const validationSchema = Yup.object().shape({
    email: Yup.string().email(t('invalidEmail')).required(t('required')),
  })

  const methods = useForm({ resolver: yupResolver(validationSchema) })

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods
  return (
    <form onSubmit={!disabled ? handleSubmit(onSubmit) : () => {}}>
      <Grid container spacing={3}>
        <Grid xs={12} item>
          <Typography variant="h2">{title}</Typography>
        </Grid>
        <Grid xs={12} item>
          <Input
            {...register('email')}
            label={t('email')}
            error={errors?.email?.message as string | undefined}
            helperText={errors?.email?.message as string | undefined}
            small
            className={classes.input}
            labelClassName={classes.label}
            required
          />
        </Grid>
        <Grid item>
          <Button type="submit" color="primary" className={classes.button} disabled={disabled}>
            {buttonText}
          </Button>
        </Grid>
        <Grid xs={12} item>
          <Typography variant="body2">{t('mandatoryFields')}</Typography>
        </Grid>
      </Grid>
    </form>
  )
}

export default EmailForm
