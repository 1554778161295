import React from 'react'
import styled, { css } from 'styled-components'
import { textColor } from 'utils/stylesHelper'

interface Props {
  lineHeight?: string | number
  fontSize?: string | number
  $bold?: boolean
  $bolder?: boolean
  fontWeight?: string
  $disabled?: boolean
  $italic?: boolean
  $color?: string
  $center?: boolean
  children?: React.ReactNode | string
}

const Text = styled.p<Props>(
  props => css`
    ${props.lineHeight
      ? css`
          line-height: ${props.lineHeight};
        `
      : css`
          line-height: 1.6;
        `}
    ${props.fontSize
      ? css`
          font-size: ${props.fontSize};
        `
      : css`
          font-size: 1rem;
        `}
    ${props.$bold &&
    css`
      font-weight: bold;
    `}
    ${props.$bolder &&
    css`
      font-weight: bolder;
    `}
    ${props.fontWeight &&
    css`
      font-weight: ${props.fontWeight};
    `}
  ${props.$disabled &&
    css`
      color: ${props.theme.palette?.text?.disabled};
    `}
  ${props.$italic &&
    css`
      font-style: italic;
    `}
  ${props.$color && textColor(props.$color)}

  ${props.$center &&
    css`
      text-align: center;
    `}
  `
)

export default Text
