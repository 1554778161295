import React from 'react'
import { Divider, IMenuItem, MenuItem } from 'components/atoms'

interface Props {
  items: IMenuItem[]
}

const SideBar: React.FC<Props> = ({ items }) => (
  <div>
    {items?.map(item =>
      item.title ? <MenuItem item={item} key={item.title} /> : <Divider key={item.title} />
    )}
  </div>
)

export default SideBar
