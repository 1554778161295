import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Typography, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Input } from 'components/atoms'
import { FieldPosition, Position, Theme } from 'utils/types'
import FormItem from './FormItem'

interface Props {
  data?: Position[]
  fields: FieldPosition[]
  startIndex?: number
  sumTitle?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  bold: { fontWeight: 600 },
  label: {
    padding: 0,
  },
  colorEnabled: {
    '&.Mui-disabled,.MuiInputBase-input': {
      color: `${theme.palette.grey[200]} !important`,
    },
    '& > .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
      borderColor: `${theme.palette.grey[500]}`,
    },
  },
  maxWidth100: {
    maxWidth: '100% !important',
  },
}))
const sumReducer = (a, index, watch, name) => {
  let returnVal = watch(`positions[${index}].${name}`)
  returnVal = parseFloat(returnVal)
  returnVal = Number.isNaN(returnVal) ? 0 : returnVal
  return a + returnVal
}

const FormGroup: React.FC<Props> = ({ fields, sumTitle, data, startIndex = 0 }) => {
  const { t } = useTranslation('common')
  const classes = useStyles()
  const { watch } = useFormContext()

  const renderSum = () => {
    const sumAmount = fields?.reduce(
      (a, _, index) => sumReducer(a, index + startIndex, watch, 'amount'),
      0
    )
    const sumAmountForeign = fields?.reduce(
      (a, _, index) => sumReducer(a, index + startIndex, watch, 'amountForeign'),
      0
    )

    return (
      <Grid
        container
        alignItems="center"
        spacing={2}
        direction="row"
        className={classes.maxWidth100}
      >
        <Grid item xs={12} sm={6} lg={6}>
          <Typography color="textSecondary" className={classes.bold}>
            {sumTitle}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={3} lg={3}>
          <div className={classes.colorEnabled}>
            <Input
              value={sumAmount}
              type="number"
              placeholder={t('amountInKilo')}
              small
              // fullWidth
              labelClassName={classes.label}
              disabled
              decimalScale={0}
              className={classes.colorEnabled}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={3} lg={3}>
          <div className={classes.colorEnabled}>
            <Input
              name="sum"
              value={sumAmountForeign}
              type="number"
              placeholder={t('amountInKilo')}
              small
              // fullWidth
              labelClassName={classes.label}
              disabled
              decimalScale={0}
              className={classes.colorEnabled}
            />
          </div>
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      {fields?.map((field, index) => (
        <FormItem key={field.position} row={field} index={index + startIndex} data={data} />
      ))}
      {sumTitle && renderSum()}
    </>
  )
}

export default FormGroup
