import React, { useContext } from 'react'
import { useHistory } from 'react-router'
import Select from 'components/atoms/Select'
import queryString from 'query-string'
import localeContext from 'utils/LocaleContext'

interface Props {
  selectClass?: string
  labelClass?: string
}

const LanguagePicker: React.FC<Props> = ({ selectClass, labelClass }) => {
  const { language, setLanguage, availableLanguages } = useContext(localeContext)
  const history = useHistory()
  const setStoredLanguage = (lang: string) => {
    setLanguage(lang)
    const parsed = queryString.parse(history.location.search)
    parsed.locale = lang
    const search = queryString.stringify(parsed)

    history.push({ pathname: history.location.pathname, search })
    window.location.reload()
  }

  return (
    <Select
      borderless
      minWidth={0}
      items={availableLanguages}
      value={language}
      onChange={setStoredLanguage}
      selectClass={selectClass}
      labelClass={labelClass}
    />
  )
}

export default LanguagePicker
